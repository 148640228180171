
const logger = (level, message, context="") => {
  switch(process.env.NODE_ENV) {
    case "production":
      if(level === "error") console.log(message)
      break;
    default:
      if(context) console.log(context, message)
      else console.log(message)
  }
}

export default logger;
