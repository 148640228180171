import logger                                   from './config/logger';
import { ShowMessage }                          from './utility';

const $sendListeningButton = $('.send-listening-button');
const $listeningTextArea = $('.listening-module-text-area')

async function sendingListeningModule (data) {
  logger("info", "sendingListeningModule");

  return new Promise((resolve, reject) => {
    $sendListeningButton.prop("disabled", true);
    $sendListeningButton.prop("text", "Wysyłanie");

    $.ajax({
      url: "https://api.localvoice.pl/v1/text",
      method: "put",
      data: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .done(function (returnedData) {
      $sendListeningButton.text("Zapisz text do przesłuchania");
      $sendListeningButton.prop("disabled", false);
      resolve();
    })
    .catch((res) => {
      $sendListeningButton.text("Zapisz text do przesłuchania");
      $sendListeningButton.prop("disabled", false);
      reject();
    })
  })
}

$sendListeningButton.on('click', async function () {
  try {
    logger("info", "click .send-listening-button");

    const text = $listeningTextArea.val();
    const body = {};
    body.text = text;

    await sendingListeningModule(body);
    const textForSuccess = "Text został wysłany";
    ShowMessage.popupTextMessageForSaveButtons(textForSuccess, this);
  } catch(e) {
    logger("error", e);
    const textForFail = "Wystąpił błąd. Text do przesłuchania nie został zapisany";
    ShowMessage.popupTextMessageForSaveButtons(textForFail, this);
  }
})
