import logger                                   from '../config/logger';


export const encodeString = (string) => {
  const result = encodeURIComponent(string).replace(/'/g, "@singlequotemark@");
  return result;
}

export const createBlobs = (parent, random, text) => {
  logger("info", "createBlobs")

  // sprawdzamy czy jaki jest ostatni elemenet na liście
  // i dodajemy aktualnemu więcej o 120%
  const bottomDimensionInProcent = checkListOfBlobs(parent);
  console.log(bottomDimensionInProcent, "bottomDimensionInProcent")

  const popup = document.createElement('span');
  $(popup)
  .addClass(`${random}`)
  .css
  ({'background-color': 'blue',
  'width': '160px',
  'background-color': '#555',
  'color': '#fff',
  'text-align': 'center',
  'border-radius': '6px',
  'padding': '8px 0',
  'position': 'absolute',
  'z-index': '1',
  'bottom': `${bottomDimensionInProcent}%`,
  'left': '50%',
  'margin-left': '-80px'
  })
  .text(text)

  $(`${parent} .popup`).append(popup)
}

export const removeBlobs = (classTarget) => {
  $('body').find(`${classTarget}`).remove()
}

const checkListOfBlobs = (parent) => {
  let bottom = 120;
  $(parent).find('span').each(function(i, el) {
    console.log(el)
    $(el).css({'bottom': `${bottom}%`})
    bottom = bottom + 120;
  })
  return bottom;
}


export const isProductionAvailable = (lesson) => {
  if(lesson.productionAvailability) return true;
  else return false;
}

export const makeProductionButtonVisible = (boolean) => {
  const productionButton = $('.sending-lesson-to-production-button');
  if(boolean) productionButton.css("display", "block");
  else productionButton.css("display", "none");
}
