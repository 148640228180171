  // import '../../public/css/style.css';
  import './clearFields';
  import './formsUtility';
  import './saveData';
  import './retrievingDataFromServer';
  import './retrievingLessonsFromServer';
  import './synonyms';
  // import './words-avability';
  import './listening-module';
  import './courses';
  import './categories';
  // import './utility';
  import './auth';
  import './structure';
  import './lessons';
  import './pictures';
  import './ideas';
  import { getElements } from "./structure";
import { call } from 'file-loader';


  let expiresAt;

  var webAuth = new auth0.WebAuth({
    domain: 'dev-6cnet9rr.eu.auth0.com',
    clientID: 'NqFkCbJy7V3RAsqgxqXm0g3mRgsmA6Dq',
    responseType: 'token id_token',
    scope: 'openid email',
    redirectUri: window.location.href
  });

  var loginBtn = document.getElementById('btn-login');

  loginBtn.addEventListener('click', function(e) {
    e.preventDefault();
    webAuth.authorize();
  });

   var loginStatus = document.querySelector('.container h4');
   var loginView = document.getElementById('login-view');
   // var homeView = document.getElementById('home-view');

   // buttons and event listeners
   // var homeViewBtn = document.getElementById('btn-home-view');
   var loginBtn = document.getElementById('btn-login');
   var logoutBtn = document.getElementById('btn-logout');

   logoutBtn.addEventListener('click', logout);

   function handleAuthentication(callback) {
     webAuth.parseHash(function(err, authResult) {
       if (authResult && authResult.accessToken && authResult.idToken) {
         window.location.hash = '';
         localLogin(authResult, function () {
           getElements();
           displayButtons();
           if(callback) {
             callback()
           }
         });
       } else {
         console.log(err);
         displayButtons();
       }
     });
   }

   function localLogin(authResult, callback) {
     // Set isLoggedIn flag in localStorage
     console.log("setting in local Storage");
     console.log(JSON.stringify(authResult))
     localStorage.setItem('isLoggedIn', 'true');
     localStorage.setItem('token', JSON.stringify(authResult));
     // Set the time that the Access Token will expire at
     expiresAt = JSON.stringify(
       authResult.expiresIn * 1000 + new Date().getTime()
     );
     callback();
   }

   function renewTokens() {
     console.log("renewTokens")
       const token1 = localStorage.getItem('token');
       const token = JSON.parse(token1);
       console.log(token, "token")
       if (token && token.accessToken && token.idToken) {
         localLogin(token, function() {
           displayButtons();
         });
       } else {
         alert('Could not get a new token');
         logout();
         displayButtons();
      }
   }

   function logout() {
     // Remove isLoggedIn flag from localStorage
     localStorage.removeItem('isLoggedIn');
     localStorage.removeItem('token');
     // Remove tokens and expiry time
     expiresAt = 0;
     displayButtons()
   }

   function isAuthenticated() {
     // Check whether the current time is past the
     // Access Token's expiry time
     var expiration = parseInt(expiresAt) || 0;
     return localStorage.getItem('isLoggedIn') === 'true' && new Date().getTime() < expiration;
   }

   function displayButtons() {
     if (isAuthenticated()) {
       sendingAccessToken()
       const token = JSON.parse(localStorage.getItem("token"))
       const email = token.idTokenPayload.email;
       $('select').prop('disabled', false)
       $('.container').find('.form-control').each(function () {
         $(this).prop( "disabled", false);
       })
       $('.container').find('.button').each(function () {
         $(this).prop( "disabled", false);
       })
       $(".email-display").css("display", "block")
       $(".email-display").text(email)
       $("#usersLessonsButton").css("display", "block")
       loginBtn.style.display = 'none';
       logoutBtn.style.display = 'inline-block';
     } else {
       $('select').prop('disabled', true)
       $('.container').find('input').each(function () {
         $(this).prop( "disabled", true);
       })
       $('.container').find('button').each(function () {
         $(this).prop( "disabled", true);
       })
       $("#usersLessonsButton").css("display", "none")
       $(".email-display").css("display", "none");
       console.log("jesteśmy w else dla displayButtons")
       loginBtn.style.display = 'inline-block';
       logoutBtn.style.display = 'none';
     }
   }

 if (localStorage.getItem('isLoggedIn') === 'true') {
    renewTokens();
     getElements();
  } else {
  handleAuthentication();
}


function sendingAccessToken() {
  const token1 = localStorage.getItem('token');
  const token = JSON.parse(token1);
  const accessToken = token.accessToken;

  console.log("accessToken")
  console.log(accessToken)
  $.ajax({
    method: 'GET',
    url: 'https://dev-6cnet9rr.eu.auth0.com/userinfo',
    headers: {
      authorization: 'Bearer ' + accessToken
    }
  })
  .done(response => {
    console.log("wiadomość z serwera")
    console.log(response)
  })
  .fail(error => {
    console.log("Nieprawidłowy accessToken")
    console.log(error)
  })
}

const auth = (callback) => {
  webAuth.authorize();
  handleAuthentication(callback);
}

export default auth;
