import { deleteImage }                          from "./pictures";
import { TargetBasedOnContext, ShowMessage,
        NoBusinessError, Token }                       from './utility';
import { saveStructure }                        from './structure';
import logger                                   from './config/logger';

const structures = (".structures");
const lessonsList = (".lessons-list");
const MaxNumberOfLessons = 13;

$(structures).on('click', '.add-lesson-button', function () {
  try {
    logger("info", "click add-lesson-button");

    const isOverNumber = blockOverNumberLessons($(this));
    if(isOverNumber) return;
    lessonStructure($(this), null);
    lessonNumberUpdate($(this));
    updateScroll();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania lekcji do struktury";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

$(lessonsList).on('click', 'img.delete-lesson', function () {
  try {
    logger("info", "click delete image lessons from list");

    const span = $(this).siblings('span')
    const deleteMessage = buildDeleteMessage($(this))
    ShowMessage.popupHTMLMessage(span, deleteMessage, 5000)
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas tworzenia budowania wiadomości dla usuwania lekcji";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


$(lessonsList).on('click', '.delete-lesson-button', async function () {
  try {
    logger("info", "img.delete-lesson-button");

    const wholeLine = $(this).parent().parent().parent().siblings(".one-line-lesson");
    const info = DeleteLessonFromDB.getInfoAboutLesson(wholeLine);
    const key = DeleteLessonFromDB.mergeKey(info);
    const response = await DeleteLessonFromDB.removeLessonFromDB(key);
    DeleteLessonFromDB.hideLessonAneDelete(wholeLine);
    ShowMessage.popupTextMessageForSaveButtons(response, this);
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas usuwana lekcji z bazy danych";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


class DeleteLessonFromDB {
  static getInfoAboutLesson(wholeLine) {
    logger("info", "DeleteLessonFromDB.getInfoAboutLesson");

    const response = {}
    response.course = $(wholeLine).data("course");
    response.category = $(wholeLine).data("category");
    response.lesson = $(wholeLine).data("lesson");
    return response;
  }

  static mergeKey({course, category, lesson}) {
    logger("info", "DeleteLessonFromDB.mergeKey");

    return `${course}_${category}_${lesson}`
  }

  static removeLessonFromDB(key) {
    logger("info", "DeleteLessonFromDB.removeLessonFromDB");

    const idToken = Token.getIdToken();
    if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

    return new Promise((resolve, reject) => {
      $.ajax({
      url: `${process.env.API_DELETE_LESSON}&key=${key}`,
      method: "delete",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
        }
      })
      .done((response) => {
        logger("info", response, "removeLessonFromDB resolve response: ");
        if(response.statusCode === 200) {
          const parsedResponse = JSON.parse(response.body);
          return resolve(parsedResponse.data);
        }
        reject(response)
      })
      .catch((error) => {
        logger("info", res, "removeLessonFromDB reject response: ");
        reject(error);
      })
    })
  }

  static hideLessonAneDelete(wholeLine) {
    logger("info", "DeleteLessonFromDB.hideLessonAneDelete");

    $(wholeLine).parent().css("display", "none")
  }
}

function buildDeleteMessage($this) {
  logger("info", "buildDeleteMessage");

  const lesson = $this.parent().siblings(".one-line-lesson").data("lesson");
  const deleteMessage = document.createElement('div');
  $(deleteMessage)

  const text = document.createElement("p")
  $(text)
  .text(`Chcesz trwale usunąć lekcję ${lesson}?`)
  .appendTo(deleteMessage)

  let removeButton = document.createElement("button");
  $(removeButton)
  .addClass("btn btn-default delete-lesson-button")
  .text("Usuń lekcje")
  .appendTo(deleteMessage);
  return deleteMessage;
}

export function buildLessonsElements(courseFromServer) {
  logger("info", "buildLessonsElements");

  $(structures).find(".english-category-input").each((index, categoryNameField) => {
    const addLessonButton = $(categoryNameField).parents(".one-category").find(".add-lesson-button");

    if($(categoryNameField).val()) {
      const englishCategoryName = $(categoryNameField).val();

      if(courseFromServer.categories && courseFromServer.categories[englishCategoryName] && courseFromServer.categories[englishCategoryName].list) {
        courseFromServer.categories[englishCategoryName].list.forEach((lesson) => {
          lessonStructure(addLessonButton, lesson)
        })
        lessonNumberUpdate(addLessonButton);
        updateScroll();
      }
    }
  })
}

function blockOverNumberLessons($currentButton) {
  logger("info", "blockOverNumberLessons")

  const $listOfLessons = $currentButton.parents(".one-category");
  let numberOfLessons = 1;
  $listOfLessons.find(".lesson-title").each((i, el) => {
    numberOfLessons+=1
  })

  if(numberOfLessons > MaxNumberOfLessons) {
    $currentButton.prop('disabled', true);
    return true;
  }
  $currentButton.prop('disabled', false);

  return false;
}

function unblockButtonToAddLessons($currentButton) {
  logger("info", "unblokButtonToAddLessons");

  const $addLessonButton = $currentButton.siblings(".add-lesson-button")
  if($addLessonButton.prop("disabled")) {
    const $listOfLessons = $currentButton.parents(".one-category");
    let numberOfLessons = 1;
    $listOfLessons.find(".lesson-title").each((i, el) => {
      numberOfLessons+=1
    })
    if(numberOfLessons <= MaxNumberOfLessons) {
      $addLessonButton.prop('disabled', false);
    }
  }
}

export function extractNamesOfLessonsFromDB(courseFromServer) {
  logger("info", "extractNamesOfLessonsFromDB");

  const tempObject = {};
  const englishLessonsNames = [];
  const polishLessonsNames = [];
    let categories = courseFromServer.categories
    for(let key in categories) {
      if(key !== "list") {
        categories[key].list.forEach((el2) => {
          englishLessonsNames.push(el2.title)
          polishLessonsNames.push(el2.description)
        })
      }
    }
  tempObject.arrayOfNamesOfLessons = englishLessonsNames;
  tempObject.arrayOfDescriptionsOfLessons = polishLessonsNames;

  return tempObject;
}

export function extractNamesOfLessons(structure) {
    logger("info", "extractNamesOfLessons")

    const result = {};
    const listOfNamesOfLessons = [];
    const listOfDescriptionsOfLessons = [];
    let description;
    let name;

    $(structure).find(".one-category").each((i, category) => {
      const englishCategoryName = $(category).find(".english-category-input").val();
      $(category).find(".one-lesson").each((n, lesson) => {
        if($(lesson).find(".english-lesson-input").val()) {
          name = $(lesson).find(".english-lesson-input").val();
          listOfNamesOfLessons.push(name);
        }
        if($(lesson).find(".polish-lesson-input").val()) {
          description = $(lesson).find(".polish-lesson-input").val();
          listOfDescriptionsOfLessons.push(description);
        }
      })
    })
    result.arrayOfNamesOfLessons = listOfNamesOfLessons;
    result.arrayOfDescriptionsOfLessons = listOfDescriptionsOfLessons;

    return result;
}

export function populateListOfLessonsInForm(listOfNamesOfLessons, listOfDescriptionsOfLessons) {
  logger("info", "populateListOfLessonsInForm");

  const $englishLessonsSelect = $(".english-lesson-select");
  const selectedOptionForEnglishLessson = $englishLessonsSelect.val();
  const $polishLessonsSelect = $(".polish-lesson-select")
  const selectedOptionForPolishLessson = $polishLessonsSelect.val();

  $englishLessonsSelect
  .find('option')
   .remove()
   .end()
   .append('<option selected="selected"></option>')
  $.each(listOfNamesOfLessons, function(i, el) {
      $englishLessonsSelect.append(`<option> ${el.toLowerCase()} </option>`);
  });
  $englishLessonsSelect.val(selectedOptionForEnglishLessson)

  $polishLessonsSelect
  .find('option')
   .remove()
   .end()
   .append('<option selected="selected"></option>')
  $.each(listOfDescriptionsOfLessons, function(i, el) {
      $polishLessonsSelect.append(`<option> ${el.toLowerCase()} </option>`);
  });
  $polishLessonsSelect.val(selectedOptionForPolishLessson);
}



function lessonNumberUpdate(currentButton) {
  logger("info", "lessonNumberUpdate");

  const $listOfLessons = currentButton.parents(".one-category");
  $listOfLessons.find(".lesson-title").each((i, el) => {
    $(el).text(`Lekcja nr ${i + 1}`)
  })
}

function updateScroll() {
  logger("info", "updateScroll");

  const elements = document.getElementsByClassName("scroll");
  for (let element of elements) {
    element.scrollTop = element.scrollHeight;
  }
}

$(structures).on('click', '.remove-lesson-button', async function () {
  try {
    logger("info", " click .remove-lesson-button");

    await removeLesson($(this));
    updateScroll();
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas usuwania lekcji ze struktury";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

async function removeLesson(context) {
    logger("info", "removeLesson");

    const target = TargetBasedOnContext.getGeneralTarget(context);
    const removeImageButton = context.parent().siblings('.one-lesson').last().find(".deletePictureButton");
    await deleteImage(removeImageButton, target);
    const oneLesson = context.parent().siblings('.one-lesson').last();
    oneLesson.remove();
    unblockButtonToAddLessons(context);
    saveStructure(context);
}

function lessonStructure(currentButton, lesson) {
  logger("info", "lessonStructure");

  const oneLesson = document.createElement('div');
  $(oneLesson)
  .addClass("one-lesson")

  const p = document.createElement('p');
  $(p)
  .addClass("lesson-title")
  .appendTo(oneLesson)

  // kolekcje dla wyzwań po angielsku
  const polishChallengeRow = document.createElement('div');
  $(polishChallengeRow)
  .addClass('english-lesson-row')
  .appendTo(oneLesson)

  const polishChallengeGroup = document.createElement('div');
  $(polishChallengeGroup)
  .addClass('wrap-input100 english-lesson-group')
  .appendTo(polishChallengeRow)

  const polishChallengeLabel = document.createElement('span');
  $(polishChallengeLabel)
  .addClass('english-lesson-label label-input100')
  .text('Nazwa lekcji po angielsku')
  .appendTo(polishChallengeGroup)

  const polishChallengeInput = document.createElement("input");
  if(lesson && lesson.title) {
    $(polishChallengeInput)
    .addClass('input100 english-lesson-input')
    .val(lesson.title)
    .appendTo(polishChallengeGroup)
  } else {
    $(polishChallengeInput)
    .addClass('input100 english-lesson-input')
    .val("")
    .appendTo(polishChallengeGroup)
  }


  // kolekcje dla wyzwań po angielsku
  const englishChallengeRow = document.createElement('div');
  $(englishChallengeRow)
  .addClass('polish-lesson-row bg1')
  .appendTo(oneLesson)

  const englishChallengeGroup = document.createElement('div');
  $(englishChallengeGroup)
  .addClass('wrap-input100 polish-lesson-group')
  .appendTo(englishChallengeRow)

  const englishChallengeLabel = document.createElement('span');
  $(englishChallengeLabel)
  .addClass('polish-challenge-label label-input100')
  .text("Nazwa/opis lekcji po polsku")
  .appendTo(englishChallengeGroup)

  const englishChallengeInput = document.createElement("input");
  if(lesson && lesson.description) {
    $(englishChallengeInput)
    .addClass('input100 polish-lesson-input')
    .val(lesson.description)
    .appendTo(englishChallengeGroup)
  } else {
    $(englishChallengeInput)
    .addClass('input100 polish-lesson-input')
    .text("")
    .appendTo(englishChallengeGroup)
  }

  const inputProcessContainer = document.createElement('div')
  $(inputProcessContainer)
  .addClass('inputProcessContainer')
  .appendTo(oneLesson)

  const image = document.createElement('img')
  const fileInput = document.createElement('input')
  const imageForDelate = document.createElement('img')
  if(lesson && lesson.image) {
    $(image)
    .addClass('myImage')
    .attr('src', lesson.image)
    .css('display', 'inline')
    .appendTo(inputProcessContainer)

    $(fileInput)
    .addClass('imageInput')
    .data("target", "lesson")
    .prop('type', 'file')
    .attr('src', "")
    .appendTo(inputProcessContainer)

    $(imageForDelate)
    .addClass('deletePictureButton')
    .css('display', 'block')
    .attr('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
    .appendTo(inputProcessContainer)
  } else {

    $(image)
    .addClass('myImage')
    .attr('src', "")
    .css('display', 'none')
    .appendTo(inputProcessContainer)

    const fileInput = document.createElement('input')
    $(fileInput)
    .addClass('imageInput')
    .data("target", "lesson")
    .prop('type', 'file')
    .attr('src', "")
    .appendTo(inputProcessContainer)

    const imageForDelate = document.createElement('img')
    $(imageForDelate)
    .addClass('deletePictureButton')
    .css('display', 'none')
    .attr('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
    .appendTo(inputProcessContainer)
  }

  const $addLessonButton = currentButton.parent();
  const oneCategory = currentButton.parents(".one-category")
  $(oneLesson).insertBefore($addLessonButton)
}
