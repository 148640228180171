import {fetchingUserAndBusiness,
        checkingUser }                                 from "./utility";
import logger                                           from "./config/logger";

const $structures = $(".structures");

export function courseStructure(course) {
  logger("info", "courseStructure")

  if(course.englishCourseName) {
    $structures.find(".structure").last().find(".course-name-input").val(course.englishCourseName);
  }
  if(course.polishCourseName) {
    $structures.find(".structure").last().find(".course-description-input").val(course.polishCourseName);
  }
  if(course.image) {
    const imageTarget = $structures.find(".structure").last().find(".course-form .myImage");
    $(imageTarget).attr("src", course.image);
    $(imageTarget).css('display', 'inline');
    const deletePicture = $structures.find(".structure").last().find(".course-form .deletePictureButton");
    $(deletePicture).css('display', 'block')
  }
}

export function extractNamesOfCoursesFromDB(courseFromServer) {
  logger("info", "extractNamesOfCoursesFromDB");

  const course = {};
  const result = {};
  course.englishCourseName = courseFromServer.englishCourseName ? courseFromServer.englishCourseName : '';
  course.polishCourseName = courseFromServer.polishCourseName ? courseFromServer.polishCourseName : '';
  course.image = courseFromServer.image ? courseFromServer.image : '';
  const name = courseFromServer.englishCourseName ? courseFromServer.englishCourseName : '';
  result.course = course;
  result.name = name;

  return result;
}

export function populateListOfCoursesInForm(listOfNamesOfCourses) {
  logger("info", "populateListOfCoursesInForm")

  const $listOfCoursesToSelect = $(".course-select");
  const selectedOption = $listOfCoursesToSelect.val();
  $listOfCoursesToSelect
  .find('option')
   .remove()
   .end()
   .append(`<option selected="selected"></option>`)
   $.each(listOfNamesOfCourses, function(i, el) {
     if(el) {
       $listOfCoursesToSelect.append(`<option> ${el.toLowerCase()} </option>`);
     }
   })
   $listOfCoursesToSelect.val(selectedOption)
}

export function extractNameOfCourse(structure) {
  logger("info", "extractNameOfCourse");

  let name;
  $(structure).find('.course-element').each(function(i, el) {
    if($(this).find(".course-name-input").val()) {
      name = $(this).find(".course-name-input").val()
    }
  })
  return name;
}
