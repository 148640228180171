import { ShowMessage, Token }                                 from "./utility";
import ValidateLesson                                         from "./utils/validateLesson";
import CustomError                                            from "./utils/errorHandler";
import logger                                                 from './config/logger';

const $wordsContainer = $('.words-container');
const $saveData = $('.save-data-button');
const $verificationData = $('.verification-data-button');
const $sendingDataToProduction = $('.sending-lesson-to-production-button')
const $courseForm = $(".my-form");

$saveData.on('click', async function () {
  try {
    logger("info", "click save lesson");
    
    await saveLesson(this);
  } catch(error) {
    logger("error", error);
    const message = (error.answer) ? error.answer : "Niestety ale wystąpił błąd podczas zapisywania lekcji"
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

$verificationData.on('click', async function () {
  try {
    logger("info", "click add lesson to test")
    await saveLesson(this, "test");
  } catch(error) {
    logger("error", error);
    ShowMessage.popupTextMessageForSaveButtons(error, this);
  }
})

$sendingDataToProduction.on('click', async function () {
  try {
    logger("info", "click add lesson to test");
    await saveLesson(this, "production");
  } catch(error) {
    logger("error", error);
    ShowMessage.popupTextMessageForSaveButtons(error, this);
  }
})

$courseForm.on('change', '.form-group', function() {
  try {
    logger("info", "change main fields form");

    const color = $(this).css("background-color")
    if(color === "crimson" || color === "rgb(220, 20, 60)") $(this).css("background-color", "#f7f7f7")
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas obsługiwania zmian w formularzu";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
});

$wordsContainer.on('change', 'input[type="checkbox"]', function () {
  try {
    logger("info", "change', 'input[type='checkbox']")

    let input = $(this).parent().siblings('.polish-facts').find('input').val()
    if(!input) {
      let checked = $(this).prop('checked', false);
    }
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas obsługiwania zmian w formularzu podczas dodawania zdjęć";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// wysyłanie danych do bazy danych
async function sendingLessonToDB(data, target) {
  logger("info", "sendingLessonToDB");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $saveData.prop("disabled", true);
    $saveData.text("Zapisywanie...");
    $.ajax({
      url: process.env.API_SAVE_LESSON,
      method: "post",
      data: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      $saveData.text("Zapisz lekcję");
      $saveData.prop("disabled", false);
      logger("info", response, "sendingLessonToDB response object");
      ShowMessage.popupTextMessageForSaveButtons(response.answer, target);
      if(response.statusCode === 200) {
        return resolve()
      }
      reject(response);
    })
    .catch((res) => {
      $saveData.text("Zapisz lekcję");
      $saveData.prop("disabled", false);
      reject(res);
    })
  })
}


// dodanie możliwości wysłania na produkcję
async function activateProductionAvailability(key, target) {
  logger("info", "activateProductionAvailability");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  const data = {};
  data.key = key;
  return new Promise((resolve, reject) => {
    $saveData.prop("disabled", true);
    $saveData.text("Zapisywanie...");
    $.ajax({
      url: process.env.API_POST_PRODUCTION_AVAILABILITY,
      method: "post",
      data: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      $saveData.text("Zapisz lekcję");
      $saveData.prop("disabled", false);
      logger("info", response, "activateProductionAvailability response object");
      ShowMessage.popupTextMessageForSaveButtons(response.answer, target);
      if(response.statusCode === 200) {
        return resolve()
      }
      reject(response);
    })
    .catch((res) => {
      $saveData.text("Zapisz lekcję");
      $saveData.prop("disabled", false);
      reject(res);
    })
  })
}




function addingNameToSendingData(data) {
  const obj = {};

  if(data && data.course && data.category && data.lesson) {
    const name = `${data.course}_${data.category}_${data.lesson}`;
    data.key = name;
    obj.name = name;
    obj.finaleData = data;

    return obj;
  } else {
    return false;
  }
}

// wysyłanie danych do bazy danych połączonej z localvoice for testers
async function dataSendingForTesting(data, target) {
  logger("info", "dataSendingForTesting");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $saveData.prop("disabled", true);

    $.ajax({
    url: process.env.API_DEV_LESSON,
    method: "post",
    data: JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      $saveData.text("Zapisz lekcję");
      $saveData.prop("disabled", false);
      logger("info", response, "dataSendingForTesting response object");
      ShowMessage.popupTextMessageForSaveButtons(response.answer, "lesson");
      if(response.statusCode === 200) {
        return resolve()
      }
      reject(response);
    })
    .catch((error) => {
      $saveData.text("Zapisz lekcję");
      $saveData.prop("disabled", false);
      reject(error)
    })
  })
}

export async function saveLesson(context, target="save") {
  try {
    logger("info", "saveLesson");

    const verifyLesson = new ValidateLesson();
    const comments = verifyLesson.validateMainFields();

    if(comments.length > 0) {
      const messageForMissingFieldsForSavedButton = "Dane nie zostały zapisane - sprawdź formularz";
      const messageForMissingFieldsForEnvButton = "Zanim przejdziesz do testowania, musisz uzupełnić podstawowe pola";
      ShowMessage.popupTextMessageForSaveButtons(messageForMissingFieldsForSavedButton, context);
      if(target === "test") ShowMessage.popupTextMessageForEnvButtons("test", messageForMissingFieldsForEnvButton, context);
      return;
    }
    const wordsPart = verifyLesson.validateWordsAndExamples();
    const listeningPart = verifyLesson.validateListeningSentences();
    const chatPart = verifyLesson.validateChat();
    const challengePart = verifyLesson.validateChallenge();
    const transfringObject = verifyLesson.mergeLessonToTransferingObject(wordsPart, listeningPart, chatPart, challengePart);
    const { finaleData, name } = addingNameToSendingData(transfringObject);
    finaleData.env = target;

    await sendingLessonToDB(finaleData, target);

    if(target === "save") return;

    const numberOfExceptions = verifyLesson.getNumberOfExceptions();
    if(numberOfExceptions > 0) {
      logger("info", numberOfExceptions, "number of Exceptions: ")
      const comment = verifyLesson.getComment();
      return ShowMessage.popupTextMessageForEnvButtons("test", comment, context);
    }

    await dataSendingForTesting(finaleData, target);
    const messageForSuccess = "Dane zostały zapisane. Poczekaj teraz 5 min i możesz zacząć testować";
    // ShowMessage.popupTextMessageForEnvButtons("test", messageForSuccess, context);
    if(target !== "test") return;
    const { versionOfTest: version } = await picVersioning(name);
    logger("info", version, "number of version: ");
    
    await activateProductionAvailability(name, target);
    picGen(version);

    // setTimeout(function () {
    //   $('.verification-data-button').prop("disabled", false).text("Testuj");
    // }, 4000);
  } catch(error) {
    console.log(error);
    throw error;
  }
}

function addingChallengesFromListeningModul(data, extraExamples) {
  logger("info", "Dodawanie extra przykladów dla wyzwań")

  const oldChallenge = data.todaysLesson.challengeForToday
  const newChallenge = data.todaysLesson.challengeForToday.concat(extraExamples)
  data.todaysLesson.challengeForToday = newChallenge;
  dataSendingForTesting(data)
}

function picGen(version) {
  try {
    logger("info", "picGen");

    const course = $('.course-select option:selected').val();
    const category = $('.english-category-select option:selected').val();
    const lesson = $('.english-lesson-select option:selected').val();
    if(course && category && lesson) {
      picGenRequest(course, category, lesson, version)
    }
  } catch(error) {
    console.log("catch in picGen", error);
    throw error;
  }
}


// wysyłanie danych do bazy danych
function picGenRequest(course, category, lesson, version) {
  logger("info", "picGenRequest");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise ((resolve, reject) => {
    const info = {}
    info.course = course;
    info.category = category,
    info.lesson = lesson;
    info.version = version;

    $.ajax({
      url: process.env.API_GENERATE_PICTURES,
      method: "post",
      data: JSON.stringify(info),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "picVersioning response object");
        return ShowMessage.popupTextMessageForEnvButtons("test", response.answer, "lesson");
    })
    .catch((error) => {
      logger("error", error, "picGenRequest error object");
      ShowMessage.popupTextMessageForSaveButtons("there was an error while genereting pictures", "lesson");
    })
  })
}

 async function picVersioning (key) {
  logger("info", "picVersioning");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise ((resolve, reject) => {
    const info = {}
    info.key = key;
    $.ajax({
      url: process.env.API_GET_VERSION_OF_PICTURE,
      method: "post",
      data: JSON.stringify(info),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "picVersioning response object");
      ShowMessage.popupTextMessageForSaveButtons(response.answer, "lesson");
      if(response.statusCode === 200) {
        logger("info", response, "response");
        const parsedResponse = JSON.parse(response.body);
        if(parsedResponse.data.versionOfTest && typeof parsedResponse.data.versionOfTest === "number") {
          return resolve(parsedResponse.data);
        }
        reject("version of test doesn't exist in response");
      }
      reject(response);
    })
    .catch((err) => {
      reject(err)
    })
  })
}
