import { ShowMessage, TargetBasedOnContext }            from "./utility";
import { deleteImage }                                  from "./pictures";
import { saveStructure }                                from "./structure";
import logger                                           from "./config/logger";

const structures = (".structures");
const $categoryForm = $('.categories .form-inline');

$categoryForm.submit(function(e) {
  e.preventDefault();
})

export function populateListOfCategoriesInForm(listOfNamesOfCategories, listOfDescriptionsOfCategories) {
    logger("info", "populateListOfCategoriesInForm");

    const $listOfCategoriesInEnglishToSelect = $(".english-category-select");
    const selectedOptionInCategoryInEnglish = $listOfCategoriesInEnglishToSelect.val();
    const $listOfCategoriesInPolishToSelect = $(".polish-category-select");
    const selectedOptionInCategoryInPolish = $listOfCategoriesInPolishToSelect.val();

    $listOfCategoriesInEnglishToSelect
    .find('option')
     .remove()
     .end()
     .append('<option selected="selected"></option>')
    $.each(listOfNamesOfCategories, function(i, el) {
        if(el) {
          $listOfCategoriesInEnglishToSelect.append(`<option> ${el.toLowerCase()} </option>`);
        }
        $listOfCategoriesInEnglishToSelect.val(selectedOptionInCategoryInEnglish);
    });

    $listOfCategoriesInPolishToSelect
    .find('option')
     .remove()
     .end()
     .append('<option selected="selected"></option>')
    $.each(listOfDescriptionsOfCategories, function(i, el) {
      if(el) {
        $listOfCategoriesInPolishToSelect.append(`<option> ${el.toLowerCase()} </option>`);
      }
    });
    $listOfCategoriesInPolishToSelect.val(selectedOptionInCategoryInPolish);
}



export function extractNamesOfCategories(structure) {
  logger("info", "extractCategories");

  const result = {};
  const englishNames = [];
  const polishNames = [];

  $(structure).find('.one-category').each(function(i, el) {
    const category = {};
    if($(this).find(".english-category-input").val()) {
      const name = $(this).find(".english-category-input").val()
      englishNames.push(name);
    }
    if($(this).find(".polish-category-input").val()) {
      const description = $(this).find(".polish-category-input").val()
      polishNames.push(description)
    }
  })
  result.arrayOfCategoriesOfNames = englishNames;
  result.arrayOfDescriptionsOfNames = polishNames;

  return result;
}

// funkcja do usuwanie kategorii z uwzględnieniem usuwania zdjęć dla kategorii i lekcji
$(structures).on("click", ".remove-categories-button", async function () {
  try {
    logger("info", ".remove-categories-button");

    const target = TargetBasedOnContext.getGeneralTarget(this);
    const removeImageButtons = $(this).parent().siblings(".form-inline").find(".category-rows").find(".one-category").last().find(".deletePictureButton").each(async (index, el) => {
      return await deleteImage(el, target);
    });
    Promise.all(removeImageButtons);
    const categoryToRemove = $(this).parent().siblings(".form-inline").find(".category-rows").find(".one-category").last();
    categoryToRemove.remove();
    saveStructure(this);
  } catch(error) {
    logger("error", error);
    const message = "Wysąpił błąd. Nie udało się usunąć zdjęcia";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


// funkcja do dodawania kategorii
$(structures).on('click', '.add-categories-button', function () {
  try {
    logger("info", ".add-categories-button event")

    buildCategoriesElements(this, [])
  } catch(e) {
    logger("error", e)
    const message = "Wystapił błąd podczas dodawania kategorii";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

//
export function extractNamesOfCategoriesFromDB(courseFromServer) {
    logger('info', "extractCategoriesFromDB");

    const result = {};
    const category = {};
    const englishCategoryNames = [];
    const polishCategoryNames = [];

    if(courseFromServer && courseFromServer.categories && courseFromServer.categories.list) {
      category.list = courseFromServer.categories.list
      category.list.forEach(category => {
        englishCategoryNames.push(category.title)
        polishCategoryNames.push(category.description)
      })
    } else {
      category.list = [];
    }
    const categories = category.list;
    result.categories = categories;
    result.arrayOfCategoriesOfNames = englishCategoryNames;
    result.arrayOfDescriptionsOfNames = polishCategoryNames;

    return result;
}

// funkcja do tworzenia kolekcji kolejnych wyzwań
export function buildCategoriesElements (context, course) {
  logger("info", "buildCategoriesElements");
  if(course.length > 0) {
      course.forEach(category => {
        categoriesStructure(context, category)
      })
  } else {
    categoriesStructure(context, false);
  }
}


function categoriesStructure(context, category) {
  logger("info", "categoriesStructure")
  console.log("context!!!!!!!!!!!!!!!!!!!!", context);
  console.log("category", category)
  const oneChallenge = document.createElement('div');
  $(oneChallenge)
  .addClass("one-category")

  const p = document.createElement('p');
  $(p)
  .appendTo(oneChallenge)

  let phraseButtons = document.createElement('div');
  $(phraseButtons)
  .addClass('phrase-buttons')
  .appendTo(oneChallenge)

  let addPhraseButton = document.createElement('button');
  $(addPhraseButton)
  .addClass("btn btn-default add-lesson-button")
  .prop("type", "submit")
  .text("Dodaj lekcję")
  .appendTo(phraseButtons)

  let removePhraseButton = document.createElement('button');
  $(removePhraseButton)
  .addClass("btn btn-default remove-lesson-button")
  .text("Usuń lekcję")
  .appendTo(phraseButtons)


  // kolekcje dla wyzwań po polsku
  const polishChallengeRow = document.createElement('div');
  $(polishChallengeRow)
  .addClass('english-category-row')
  .appendTo(oneChallenge)

  const polishChallengeGroup = document.createElement('div');
  $(polishChallengeGroup)
  .addClass('wrap-input100 english-category-group')
  .appendTo(polishChallengeRow)

  const polishChallengeLabel = document.createElement('span');
  $(polishChallengeLabel)
  .addClass('english-category-label label-input100')
  .html('Nazwa kategorii po angielsku <br> musi posiadać słowo "Category"')

  .appendTo(polishChallengeGroup)

  const polishChallengeInput = document.createElement("input");
  if(category.title) {
    $(polishChallengeInput)
    .addClass('input100 english-category-input')
    .val(category.title)
    .appendTo(polishChallengeGroup)
  } else {
    $(polishChallengeInput)
    .addClass('input100 english-category-input')
    .val("")
    .appendTo(polishChallengeGroup)
  }


  // kolekcje dla wyzwań po angielsku
  const englishChallengeRow = document.createElement('div')
  $(englishChallengeRow)
  .addClass('polish-category-row bg1')
  .appendTo(oneChallenge)

  const englishChallengeGroup = document.createElement('div');
  $(englishChallengeGroup)
  .addClass('wrap-input100 polish-category-group')
  .appendTo(englishChallengeRow)

  const englishChallengeLabel = document.createElement('span');
  $(englishChallengeLabel)
  .addClass('polish-challenge-label label-input100')
  .text("Opis/nazwa kategorii po polsku")
  .appendTo(englishChallengeGroup)

  const englishChallengeInput = document.createElement("input");
  if(category.description) {
    $(englishChallengeInput)
    .addClass('input100 polish-category-input')
    .val(category.description)
    .appendTo(englishChallengeGroup)
  } else {
    $(englishChallengeInput)
    .addClass('input100 polish-category-input')
    .text("")
    .appendTo(englishChallengeGroup)
  }

  const inputProcessContainer = document.createElement('div')
  $(inputProcessContainer)
  .addClass('inputProcessContainer')
  .appendTo(oneChallenge)

  const image = document.createElement('img')
  const fileInput = document.createElement('input')
  const imageForDelate = document.createElement('img')

  if(category.image) {
    $(image)
    .addClass('myImage category-image')
    .attr('src', category.image)
    .css('display', 'inline')
    .appendTo(inputProcessContainer)

    $(fileInput)
    .addClass('imageInput')
    .data("target", "category")
    .prop('type', 'file')
    .attr('src', "")
    .appendTo(inputProcessContainer)

    $(imageForDelate)
    .addClass('deletePictureButton')
    .css('display', 'block')
    .attr('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
    .appendTo(inputProcessContainer)

  } else {
    $(image)
    .addClass('myImage category-image')
    .attr('src', "")
    .css('display', 'none')
    .appendTo(inputProcessContainer)

    const fileInput = document.createElement('input')
    $(fileInput)
    .addClass('imageInput')
    .data("target", "category")
    .prop('type', 'file')
    .attr('src', "")
    .appendTo(inputProcessContainer)

    const imageForDelate = document.createElement('img')
    $(imageForDelate)
    .addClass('deletePictureButton')
    .css('display', 'none')
    .attr('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
    .appendTo(inputProcessContainer)
  }
  let currentButton;
  if(!context) currentButton = $(structures).find(".structure").last().find(".add-categories-button");
  else currentButton = $(context).closest(".structure").last().find(".add-categories-button");
  
  const categoryRows = currentButton.parent().siblings(".category-form").find(".category-rows");
  $(oneChallenge).appendTo(categoryRows)
}
