import { ShowMessage, TargetBasedOnContext, 
        NoBusinessError, baseCheckingBeforeSavingPicture,
         Token }                                                  from "./utility";
import { saveLesson }                                             from "./saveData";
import { saveStructure }                                          from "./structure";
import logger                                                     from './config/logger';

const body = ('body');

function detectRemovingImageInfo(currentObject) {
  logger("info", "detectRemovingImageInfo");

  const result = {};
  const img = $(currentObject).siblings('img.myImage');
  const src = img.attr('src');
  if(!src) return null;
  const fileName = src.slice(src.lastIndexOf("/")+1);
  result.img = img;
  result.src = src;
  result.fileName = fileName;
  return result;
}

export async function deleteImage(removingButton, target) {
    logger("info", 'deleteImage');

    const result = detectRemovingImageInfo(removingButton);
    if(!result) return;
    await imageIsDeleting(result.fileName, target);
    result.img.attr('src', '');
    $(removingButton).css("display", "none");
    result.img.css("display", "none");
    let input = removingButton.previousElementSibling;

    if(input) input.value = '';
}

$(body).on('click', '.deletePictureButton', async function () {
  try {
    logger("info", "deletePictureButton");

    const target = TargetBasedOnContext.getTarget(this);
    await deleteImage(this, target) ;

    if(target === "lesson-icons") {
      return saveLesson(this);
    }
    return saveStructure(this);
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas usuwana zdjęcia";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

export function imageIsDeleting(fileName, target) {
  logger("info", "imageIsDeleting");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${process.env.API_DELETE_PICTURE}?filename=${fileName}&target=${target}`,
      method: "delete",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "imageIsDeleting response object");
      ShowMessage.popupTextMessageForSaveButtons(response.answer, target);
      if(response.statusCode === 200) {
        return resolve();
      }
      resolve();
    })
    .fail((error) => {
      const deleteImageErrorMessage = "there was an error while deleting image";
      ShowMessage.popupTextMessageForSaveButtons(deleteImageErrorMessage, target);
      reject(error);
    });
  })
}

function uploadImageToImputFile(data, file, img, deleteImage) {
  logger("info", "uploadImageToImputFile");

  const formData = new FormData();
  formData.append("Content-Type", file.type);
  Object.entries(data.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", file);
  img.style.display = 'inline';
  deleteImage.style.display = 'block';

  return formData;
}

function setImagePathToSrc(img, fullPath, deleteImage) {
  logger("info", "setImagePathToSrc");

  img.src = fullPath;
  img.style.display = 'inline';
  deleteImage.style.display = 'block';
}

// sprawdzamy gdzie jesteśmy, aby następnie zapisać określone typy danych (lekcje albo strukturę)
$(body).on('change', 'input[type="file"]', async function () {
  try {
    logger("info", "change input picture");

    if(this.files && this.files[0]) {
      const { format, file, img, deleteCrossImage } = detectAddingImageInfo(this);
      const target = TargetBasedOnContext.getTarget(this);

      const isFormCompleted = baseCheckingBeforeSavingPicture(target);
      if(!isFormCompleted) return;
      const { credentials, fullPath } = await getS3Credentials(format, target);
      logger("info", fullPath, "ścieżka do zapisanego zdjęcia: ");

      const formData = uploadImageToImputFile(credentials, file, img, deleteCrossImage);
      await imageIsSavingInS3(credentials, formData, target);
      setImagePathToSrc(img, fullPath, deleteCrossImage);
      logger("info", target, "target for adding picture");

      if(target === "lesson-icons") {
        return saveLesson(target);
      }
      return saveStructure(target);
    } else {
      const message = "Zdjęcie nie zostało dodane";
      ShowMessage.popupTextMessageForSaveButtons(message, this);
    }
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas dodawania zdjęcia";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
});


function detectAddingImageInfo(currentObject) {
  logger("info", "detectAddingImageInfo");

  const result = {}
  const img = currentObject.previousElementSibling;
  img.src = URL.createObjectURL(currentObject.files[0]);
  const deleteCrossImage = currentObject.nextElementSibling;
  let file = currentObject.files[0];
  const format = file.name.slice(file.name.lastIndexOf(".")+1 );
  result.format = format;
  result.img = img;
  result.file = file;
  result.deleteCrossImage = deleteCrossImage;

  return result
}

// boolean wskazuję, czy mamy po wszystkim zapisać zmiany czy nie
async function getS3Credentials(format, target) {
  logger("info", "getS3Credentials");
  
  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${process.env.API_GET_IMAGE_CREDENTIALS}${format}&target=${target}`,
      method: "get",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "getS3Credentials response object");
      ShowMessage.popupTextMessageForSaveButtons(response, target);
      if(response.statusCode === 200) {
        const parsedResponse = JSON.parse(response.body);
        console.log("jesteśmy po AWS credentials", parsedResponse.data)
        return resolve(parsedResponse.data);
      }
      reject(response)
    })
    .fail((err) => {
      reject(err)
    });
  })
}


async function imageIsSavingInS3(data, formData, target) {
  logger("info", "imageIsSavingInS3");

  return new Promise((resolve, reject) => {
    fetch(data.url, {
     method: "POST",
     body: formData,
   })
   .then(() => {
      const saveImageMessage = "image was successfully saved";
      ShowMessage.popupTextMessageForSaveButtons(saveImageMessage, target);
      resolve()
   })
   .catch((error) => {
      const saveImageErrorMessage = "there was an error while saving message";
      ShowMessage.popupTextMessageForSaveButtons(saveImageErrorMessage, target);
      reject(error);
    })
  })
}
