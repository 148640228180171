import { imageIsDeleting }                      from './pictures';
import { checkingUser,
        fetchingUserAndBusiness,
        NoBusinessError, ShowMessage }          from "./utility";
import { saveLesson }                           from "./saveData";
import logger                                   from "./config/logger";

const $numberOfExamples = $('.number-of-examples');
const $newWordButton = $('.new-word-button');
const $wordsContainer = $('.words-container');
const $removeWordButton = $('.remove-word-button');
const $formInline = $('.form-inline.sentences')
const $allWordsSentenceContainer = $('.all-words-sentence-container');
const $chatForm = $('.form-inline.chat');
const $challengeForm = $('.form-inline.challenge');
const $form = $('.my-form');
let data = {};

const MAX_EXAMPLES = 20
const MAX_WORDS = 20
const MAX_PHRASES = 20;
const MIN_PHRASES = 0;
const MAX_EXAMPLES_FOR_SENTENCES = 20;
const MIN_EXAMPLES_FOR_SENTENCES = 0;
const MIN_EXAMPLES_FOR_WORD = 0;
const MIN_WORDS = 0;

wordColectionCreatingWithMinNumber(MIN_WORDS);
dataEnteringForWords();
examplesColectionCreatingWithMinNumber(MIN_EXAMPLES_FOR_WORD);
dataEnteringForWords();
dataEnteringForSentences();
dataEnteringForChallenge();
optionsOnStart();
dataEnteringForWords();
dataEnteringForSentences();
dataEnteringForChallenge();

//funkcja do dodawania dialogów czatu
$chatForm.on("click", ".add-chat-button", function () {
  try {
    logger("info", "click add-chat-button")

    chatCollectionCreating($(this));
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania czatu";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

$form.submit(function(e) {
  e.preventDefault();
})

$formInline.submit(function(e) {
  e.preventDefault();
})

$chatForm.submit(function (e) {
  e.preventDefault();
})

$challengeForm.submit(function (e) {
  e.preventDefault();
})

// funkcja tworzenia nowego słówka dla sentencji
$formInline.on('click', '.new-word-sentence-button', function () {
  try {
    logger("info", "click new-word-sentence-button");

    sentenceCollectionCreating($(this), true);
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania nowego słowa";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

$wordsContainer.on('change', '.part-of-the-speech', function () {
  try {
    logger("info", "part-of-the-speech");

    $('.words-container').find('.word-container').each(function(i, el) {
      $(this).find('.words-examples').each(function (i, el2) {
        if($(el2).find("option:selected").val() === "Popularna fraza") {
          $(el2).children(".polish-word-explanation").hide()
        } else {
          $(el2).children(".polish-word-explanation").show()
        }
      })
    })
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania nowego słowa";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// funkcja do dodawania wyzwań
$challengeForm.on('click', '.add-challenge-button', function () {
  try {
    logger("info", "click add-challenge-button");

    challengeCollectionCreating($(this))
    dataEnteringForChallenge();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania nowego wyzwania";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// event dla automatycznego dodawania wyzwań po angielsku

$wordsContainer.on('input', '.english-word-example-input',function () {
  try {
    logger("info", "click english-word-example-input")

    const indexOfInput = $(this).parent().parent().parent().attr("id");
    const wordsExample = $(this);
    const input = $(this).val();

    automatedChallengeCollectionCreating(indexOfInput, input, true);
    dataEnteringForChallenge();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas automatyczngo dodawania wyzwań";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


// event do automatycznego dodawania wyzwań po polsku
$wordsContainer.on('input', '.polish-word-example-input',function () {
  try {
    logger("info", "click polish-word-example-input");

    const indexOfInput = $(this).parent().parent().parent().attr("id");
    const wordsExample = $(this);
    const input = $(this).val();

    automatedChallengeCollectionCreating(indexOfInput, input);
    dataEnteringForChallenge();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas automatyczngo dodawania wyzwań";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


//funkcja do usuwania wyzwań
$challengeForm.on("click", ".remove-challenge-button", function () {
  try {
    logger("info", "click remove-challenge-button")

    const $elementToRemove = $(this).closest(".challenge-rows").find(".one-challenge").last();
    $elementToRemove.remove();
    dataEnteringForChallenge();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas usuwania wyzwania";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

//funkcja do usuwania dialogów czatu
$chatForm.on("click", ".remove-chat-button", function () {
  try {
    logger("info", "click remove-chat-button")

    const $elementToRemove = $(this).parent().siblings(".one-chat-row").last();
    $elementToRemove.remove();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas usuwania pola czatu";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


// funkcja do usuwanie ostatniego słówka dla sentencji
$formInline.on('click', '.remove-word-sentence-button', function () {
  try {
    logger("info", "click remove-word-sentence-button");

    const $elementToRemove = $(this).parent().siblings(".one-word-sentences-container").last();
    $elementToRemove.remove();
    dataEnteringForWords();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas usuwania pola dla słowa";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// funkcja do usuwania przykładów sentencji
$allWordsSentenceContainer.on('click' ,'.remove-sentence-button',function () {
  try {
    logger("info", "click remove-sentence-button");

    const $elementToRemove = $(this).parent().siblings(".sentence-container").last();
    $elementToRemove.remove();
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas usuwania przykładu";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


// funkcja dodawania nowego przykładu dla zdań złożonych
$formInline.on('click', ".add-sentence-button", function () {
  try {
    logger("info", "click add-sentence-button");

    sentenceCollectionCreating($(this));
    dataEnteringForSentences()
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania przykładu z rozumienia ze słuchu";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// funkcja do dodawania przykładów dla słówek
$wordsContainer.on('click', '.add-examples-button',function () {
  try {
    logger("info", "click add-examples-button");

    examplesColectionCreating($(this), false);
    dataEnteringForWords();
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania przykładów dla słówek";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// funkcja do dodawania extra przykładów (gdy np. sobie o czymś przypomnimy po czasie)
$wordsContainer.on('click', '.extra-button',function () {
  try {
    logger("info", "click extra-button");

    const parent = $(this).parent();
    examplesColectionCreating(parent, false, true);
    dataEnteringForWords();
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania extra przykładów dla słówek";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// funkcja do usuwania przykładów dla słówek
$wordsContainer.on('click' ,'.remove-examples-button', async function () {
  try {
    logger("info", "click remove-examples-button");
    const $elementToRemove = $(this).siblings('.words-examples').last();
    const $pictureToRemove = $(this).siblings('.words-examples').last().find(".myImage");
    const src = $pictureToRemove.attr('src') ? $pictureToRemove.attr('src') : '';
    $elementToRemove.remove();
    if(src) {
      const fileName = src.slice(src.lastIndexOf("/")+1);
      await imageIsDeleting(fileName, "lesson");
      await saveLesson(this);
    }

    dataEnteringForWords();
    dataEnteringForSentences();

  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas dodawania extra przykładów dla słówek";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
});


$wordsContainer.on('click' ,'.delete-extra-button', async function () {
  try {
    logger("info", "click delete-extra-button");

    const $elementToRemove = $(this).parent('.words-examples');
    const $pictureToRemove = $(this).parent('.words-examples').find(".myImage");
    const src = $pictureToRemove.attr('src') ? $pictureToRemove.attr('src') : '';
    $elementToRemove.remove();
    if(src) {
      const fileName = src.slice(src.lastIndexOf("/")+1);
      await imageIsDeleting(fileName, "lesson");
    }
    dataEnteringForWords();
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas usuwania przykładu dla słówka";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }

});

// funkcja do dodawania nowych słow
$newWordButton.on('click', function () {
  try {
    logger("info", "click newWordButton");

    wordColectionCreating();
    dataEnteringForWords();
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas dodawania nowego słówka";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

// funkcja do usuwania nowych słow
$removeWordButton.on('click', async function () {
  try {
    logger("info", "click $removeWordButton");

    const promises = [];
    const $elementToRemove = $('.words-container').children().last();
    const sources = findingImages($elementToRemove)

    if(sources.length > 0) {
      sources.forEach(el => {
        const result = imageIsDeleting(el, "lesson")
        promises.push(result)
      })
    }
    await Promise.all(promises);

    $elementToRemove.remove();
    dataEnteringForWords();
    dataEnteringForSentences()
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas usuwania całego słówka";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

function findingImages($element) {
  logger("info", "findingImages");

  const array = [];
  let fileName;
  let src;

  $element.find('.myImage').each(function(i, el) {
    src = $(this).attr('src') ? $(this).attr('src') : '';
    if(src) {
      const fileName = src.slice(src.lastIndexOf("/")+1)
      array.push(fileName)
    }
  })
  return array;
}


// funkcja do dodawania pojedyńczej frazy w sentancjach
$formInline.on('click', '.add-phrase-button', function () {
  addingSimplePhrase($(this))
  dataEnteringForSentences();
})

// funkcja do dodawania pojedyńczej frazy w sentencjach
$formInline.on('click', '.remove-phrase-button', function () {
  try {
    logger("info", "click remove-phrase-button");

    removeSimplePhrase($(this));
    dataEnteringForSentences();
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas usuwania przykładu";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


// funkcja do odświerzania danych w kolekcji dla słów
export function dataEnteringForWords () {
  logger("info", "dataEnteringForWords");

  let counter = 0;
  $('.words-container').find('.word-container').each(function(i, el) {
    $(el).find(".english-word-label").text(rightOrderForWords(i));
    const that = this;
    $(that).find(".polish-word-example-label").each(function (j, el1) {
      $(el1).text(rightOrderForWordsExamples(j, false));

    })
    $(that).find(".part-of-the-speech-label").each(function (l, el2) {
      $(el2).text("Jaka to część mowy w tym przykładzie")

    })
    $(that).find(".polish-word-explanation-label").each(function (m, el3) {
      $(el3).text("Polskie znaczenie słówka w tym przykładzie")

    })
    $(that).find(".english-word-example-label").each(function (k, el4) {
      $(el4).text(rightOrderForWordsExamples(k, true));
      const data = $(that).find(".add-examples-button");
      disableButton(k, MAX_EXAMPLES, data);
    })
    $(that).find(".polish-facts-label").each(function (m, el3) {
      $(el3).text("Ciekawostki gramatyczne/ogólne - nieobowiązkowe (będą widoczne u góry nad postacią)")

    })
    $(this).find('.words-examples').each(function (n, el5) {
      let string = counter.toString();
      $(el5).attr('id', `${string}`);
      counter++;
    })
    const secondData = $(".new-word-button");
    disableButton(i, MAX_WORDS, secondData)
  })

  insertNumberOfExamples()
}


// dodajemy liczbę przykładów widoczną na górze
function insertNumberOfExamples () {
  logger("info", "insertNumberOfExamples");

   let numberOfExamples;
   $('.words-examples').each(function(i, el) {
     numberOfExamples =+ i;
   })
   if(numberOfExamples == 0) {
     numberOfExamples = 0
   } else {
     numberOfExamples = numberOfExamples + 1;
   }
   $numberOfExamples.text(numberOfExamples)
}


// funkcja do odświerzania danych w wyzwaniach
export function dataEnteringForChallenge () {
  logger("info", "dataEnteringForChallenge");

  $('.challenge-rows').find('.one-challenge').each(function(i, el) {
    const that = this;
    $(this).children("p").text(`Wyzwanie nr ${i+1}`);
    $(this).children(".english-challenge-row").each(function (j, ele) {
      $(this).find("label").text("Wyzwanie po angielsku")
    })
    $(el).children(".polish-challenge-row").each(function (k, ele) {
      $(this).find("label").text("Wyzwanie po polsku");
    })
  })
}

// funkcja do odświerzania danych w sentencjach
export function dataEnteringForSentences () {
  logger("info", "dataEnteringForSentences");

  $('.all-words-sentence-container').find('.one-word-sentences-container').each(function(i, el) {
    const myThat = this;
    $(el).find("h3").text(nameOfTheCurrentWordRetrieving(i))
    $(el).find(".sentence-container").each(function (j, ele) {
      const that = this;
      const addSentenceButton = $(myThat).find(".add-sentence-button");
      const removeButton = $(myThat).find('.remove-sentence-button')
      disableButton(j, MAX_EXAMPLES_FOR_SENTENCES, addSentenceButton);
      disableRemoveButton(j, MIN_EXAMPLES_FOR_SENTENCES, removeButton)
      $(this).children("p").text(rightOrderForSentenceExamplesLabels(j))
      $(ele).children(".english-sentence-example").each(function(k, elee) {
        $(elee).find(".english-phrase").each(function (l, eleee) {
          $(eleee).find("label").text(rightOrderForSentenctions(l, false));
        })
      })
      $(ele).children(".polish-sentence-example").each(function(k, elee) {
        $(elee).find(".polish-phrase").each(function (l, eleee) {
          $(eleee).find("label").text(rightOrderForSentenctions(l, true));
          const addButton = $(that).find('.add-phrase-button')
          const removeButton = $(that).find('.remove-phrase-button');
          disableButton(l, MAX_PHRASES, addButton)
          disableRemoveButton(l, MIN_PHRASES, removeButton)
        })
      })
    })
  })
}

// funkcja do pobierania nazwy konkretnego słówka z modułu word do sentences
export function nameOfTheCurrentWordRetrieving (number) {
  logger('info', 'nameOfTheCurrentWordRetrieving');

  let text = undefined;
  $('.words-container').find('.word-container').each(function(i, el) {
    if(number == i) {
      let currentWord = $(this).find('.english-word-input').val()
      if(!currentWord) {
        text = `Przykłady do słówka nr ${i+1}`
      } else {
        text = `Przykłady do słówka ${currentWord}`
      }
    }
  })
  if(text === undefined) {
    text = `Przykłady do słówka nr ${number + 1}`
  }
  return text;
}


// funkcja do tworzenia kolekcji kolejnych wyzwań
export function challengeCollectionCreating (currentButton) {
  logger("info", "challengeCollectionCreating");

  const oneChallenge = document.createElement('div');
  $(oneChallenge)
  .addClass("one-challenge")

  const p = document.createElement('p');
  $(p)
  .appendTo(oneChallenge)

  // kolekcje dla wyzwań po polsku
  const polishChallengeRow = document.createElement('div')
  $(polishChallengeRow)
  .addClass('polish-challenge-row')
  .appendTo(oneChallenge)

  const polishChallengeGroup = document.createElement('div');
  $(polishChallengeGroup)
  .addClass('form-group polish-challenge-group input100-select wrap-input100 bg1')
  .appendTo(polishChallengeRow)

  const polishChallengeLabel = document.createElement('span');
  $(polishChallengeLabel)
  .addClass('polish-challenge-label label-input100')
  .text('Wyzwanie po polsku')
  .appendTo(polishChallengeGroup)

  const polishChallengeInput = document.createElement("input");
  $(polishChallengeInput)
  .addClass('input100 polish-challenge-input')
  .prop("disabled", true)
  .appendTo(polishChallengeGroup)

  // kolekcje dla wyzwań po angielsku
  const englishChallengeRow = document.createElement('div')
  $(englishChallengeRow)
  .addClass('english-challenge-row')
  .appendTo(oneChallenge)

  const englishChallengeGroup = document.createElement('div');
  $(englishChallengeGroup)
  .addClass('form-group english-challenge-group input100-select wrap-input100')
  .appendTo(englishChallengeRow)

  const englishChallengeLabel = document.createElement('span');
  $(englishChallengeLabel)
  .addClass('english-challenge-label label-input100')
  .text("Wyzwanie po angielsku")
  .appendTo(englishChallengeGroup)

  const englishChallengeInput = document.createElement("input");
  $(englishChallengeInput)
  .addClass('input100 english-challenge-input')
  .prop("disabled", true)
  .appendTo(englishChallengeGroup)

  currentButton.parent().before(oneChallenge)
}

// dodawanie dwóch równoległych elementów dla pojedyńczego przykładu zdań złożonych
export function addingSimplePhrase (currentButton) {
  logger("info", "addingSimplePhrase");

  const $currentEnglishCollection = currentButton.parent().siblings('.english-sentence-example');
  const $currentPolishCollection = currentButton.parent().siblings('.polish-sentence-example')

  // frazy dla kolekcji po polsku
  let polishPhrase = document.createElement("div");
  $(polishPhrase)
  .addClass('form-group polish-phrase another-line')
  .appendTo($currentPolishCollection)

  let labelForPolishPhrase = document.createElement('label')
  $(labelForPolishPhrase)
  .addClass("label-for-polish-phrase")
  .text("Kolejna część zdania")
  .appendTo(polishPhrase)

  let inputForPolishPhrase = document.createElement('input');
  $(inputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .appendTo(polishPhrase)
}

// funkcja pomocnicza dla dodawania wyzwań
function insertAtIndex(i, input, boolean) {
  logger("info", "insertAtIndex")

  const oneChallenge = document.createElement('div');
  $(oneChallenge)
  .addClass("one-challenge")

  const p = document.createElement('p');
  $(p)
  .appendTo(oneChallenge)

  // kolekcje dla wyzwań po polsku
  const polishChallengeRow = document.createElement('div')
  $(polishChallengeRow)
  .addClass('polish-challenge-row')
  .appendTo(oneChallenge)

  const polishChallengeGroup = document.createElement('div');
  $(polishChallengeGroup)
  .addClass('form-group polish-challenge-group input100-select wrap-input100')
  .appendTo(polishChallengeRow)

  const polishChallengeLabel = document.createElement('label');
  $(polishChallengeLabel)
  .addClass('polish-challenge-label label-input100')
  .text('Wyzwanie po polsku')
  .appendTo(polishChallengeGroup)

  if(!boolean) {
    const polishChallengeInput = document.createElement("input");
    $(polishChallengeInput)
    .addClass('input100 polish-challenge-input')
    .prop("disabled", true)
    .appendTo(polishChallengeGroup)
  } else {
    const polishChallengeInput = document.createElement("input");
    $(polishChallengeInput)
    .addClass('input100 polish-challenge-input')
    .prop("disabled", true)
    .text(input)
    .appendTo(polishChallengeGroup)
  }

  // kolekcje dla wyzwań po angielsku
  const englishChallengeRow = document.createElement('div')
  $(englishChallengeRow)
  .addClass('english-challenge-row')
  .appendTo(oneChallenge)

  const englishChallengeGroup = document.createElement('div');
  $(englishChallengeGroup)
  .addClass('form-group english-challenge-group input100-select wrap-input100')
  .appendTo(englishChallengeRow)

  const englishChallengeLabel = document.createElement('label');
  $(englishChallengeLabel)
  .addClass('english-challenge-label label-input100')
  .text("Wyzwanie po angielsku")
  .appendTo(englishChallengeGroup)
  if(boolean) {
    const englishChallengeInput = document.createElement("input");
    $(englishChallengeInput)
    .addClass('input100 english-challenge-input')
    .prop("disabled", true)
    .text(input)
    .appendTo(englishChallengeGroup)
  } else {
    const englishChallengeInput = document.createElement("input");
    $(englishChallengeInput)
    .addClass('input100 english-challenge-input')
    .prop("disabled", true)
    .appendTo(englishChallengeGroup)
  }

  if(i === 1) {
   $(".challenge-rows").prepend(oneChallenge);
   return;
  }
  $(oneChallenge).insertAfter($(".challenge-rows > .one-challenge:nth-child(" + (i - 1) + ")"))
}

// automatyczne dodawanie wyzwań !!!!!!!!!!
function automatedChallengeCollectionCreating (indexOfInput, input, boolean) {
  logger("info", "automatedChallengeCollectionCreating");

  let index;
  let numberOfExamples;
  $('.words-examples').each(function(i, el) {
    numberOfExamples =+ i;
    if(indexOfInput == i) {
      index = i;
    }
  })
  const properIndex = index + 1;
  if($(".challenge-rows > .one-challenge").length >= properIndex ) {
    if(boolean) {
      $(".challenge-rows > div:nth-child(" + (properIndex) + ")").find(".english-challenge-input").val(input)
    } else {
      $(".challenge-rows > div:nth-child(" + (properIndex) + ")").find(".polish-challenge-input").val(input)
    }
  } else {
    insertAtIndex(properIndex, input, boolean)
  }
}

export function disableButton (i, max, data) {
  logger("info", "disableButton")

  if (data) {
    if(i >= max) {
      $(data).prop("disabled", true);

    } else {
      $(data).prop("disabled", false);
    }
  }
}

// funkcja do tworzenia kolekcji czatu
export function chatCollectionCreating (currentButton) {
  logger("info", "chatCollectionCreating");

  const oneChatRow = document.createElement("div");
  $(oneChatRow)
  .addClass("one-chat-row")

  // kolekcje dla usera po angielsku
  const englishChatRow = document.createElement("div")
  $(englishChatRow)
  .addClass("english-chat-row")
  .appendTo(oneChatRow)


  const englishUserFormGroup = document.createElement("div");
  $(englishUserFormGroup)
  .addClass("form-group english-user-group input100-select wrap-input100 bg1")
  .appendTo(englishChatRow)

  const englishLabelForUserRole = document.createElement("span");
  $(englishLabelForUserRole)
  .addClass("english-role-user-label label-input100")
  .text("Rola google po angielsku")
  .appendTo(englishUserFormGroup)

  const englishInputForUserRole = document.createElement("input");
  $(englishInputForUserRole)
  .addClass("english-role-user-input input100")
  .prop("id", "")
  .appendTo(englishUserFormGroup)

  // kolekcje dla google po angielsku
  const englishGoogleFormGroup = document.createElement("div");
  $(englishGoogleFormGroup)
  .addClass("form-group english-google-group input100-select wrap-input100 bg1")
  .appendTo(englishChatRow)

  const englishLabelForGoogleRole = document.createElement("span");
  $(englishLabelForGoogleRole)
  .addClass("english-role-google-label label-input100")
  .text("Rola usera po angielsku")
  .appendTo(englishGoogleFormGroup)

  const englishInputForGoogleRole = document.createElement("input");
  $(englishInputForGoogleRole)
  .addClass("input100 english-role-google-input")
  .prop("id", "")
  .appendTo(englishGoogleFormGroup)

  // kolekcje dla usera po polsku
  const polishChatRow = document.createElement("div")
  $(polishChatRow)
  .addClass("polish-chat-row")
  .appendTo(oneChatRow)

  const polishUserFormGroup = document.createElement("div");
  $(polishUserFormGroup)
  .addClass("form-group polish-user-group input100-select wrap-input100 bg1")
  .appendTo(polishChatRow)

  const polishLabelForUserRole = document.createElement("span");
  $(polishLabelForUserRole)
  .addClass("polish-role-user-label label-input100")
  .text("Rola google po polsku")
  .appendTo(polishUserFormGroup)

  const polishInputForUserRole = document.createElement("input");
  $(polishInputForUserRole)
  .addClass("input100 polish-role-user-input")
  .prop("id", "")
  .appendTo(polishUserFormGroup)

  // kolekcje dla google po polsku
  const polishGoogleFormGroup = document.createElement("div");
  $(polishGoogleFormGroup)
  .addClass("form-group polish-user-group input100-select wrap-input100 bg1")
  .appendTo(polishChatRow)

  const polishLabelForGoogleRole = document.createElement("span");
  $(polishLabelForGoogleRole)
  .addClass("polish-role-google-label label-input100")
  .text("Rola usera po polsku")
  .appendTo(polishGoogleFormGroup)

  const polishInputForGoogleRole = document.createElement("input");
  $(polishInputForGoogleRole)
  .addClass("polish-role-google-input input100")
  .prop("id", "")
  .appendTo(polishGoogleFormGroup)

  currentButton.parent().before(oneChatRow)
}


// funkcja do tworzenia kolekcji sentencji
export function sentenceCollectionCreating (currentButton, boolean) {
  logger("info", "sentenceCollectionCreating");

  const sentenceContaner = document.createElement('div');
  const oneWordSentencesContainer = document.createElement("div");
  const h3 = document.createElement('h3');

  if(boolean) {
    $(oneWordSentencesContainer)
    .addClass("one-word-sentences-container")

    $(h3)
    .appendTo(oneWordSentencesContainer)

    $(sentenceContaner)
    .addClass("sentence-container")
    .appendTo(oneWordSentencesContainer)
  } else {
    $(sentenceContaner)
    .addClass("sentence-container")
  }

  const p = document.createElement('p');
  $(p)
  .appendTo(sentenceContaner);

  // frazy dla kolekcji po angielsku
  const englishSentenceExample = document.createElement('div');
  $(englishSentenceExample)
  .addClass('english-sentence-example')
  .appendTo(sentenceContaner)

  const englishPhrase = document.createElement("div");
  $(englishPhrase)
  .addClass('form-group english-phrase')
  .appendTo(englishSentenceExample)

  const labelForEnglishPhrase = document.createElement('label')
  $(labelForEnglishPhrase)
  .addClass('label-for-listening-comprehension-link-input')
  .text("Kolejna część zdania")
  .prop("placeholder", "Kolejna część zdania")
  .appendTo(englishPhrase)

  const inputForEnglishPhrase = document.createElement('input');
  $(inputForEnglishPhrase)
  .addClass('form-control listening-comprehension-link-input')
  .prop("placeholder", "https://..")
  .prop("type", "text")
  .prop("id", "whatever")
  .appendTo(englishPhrase)

  // 2 pole
  const secondEnglishPhrase = document.createElement('div')
  $(secondEnglishPhrase)
  .addClass('form-group english-phrase another-line')
  .appendTo(englishSentenceExample)

  const secondlabelForEnglishPhrase = document.createElement("label")
  $(secondlabelForEnglishPhrase)
  .addClass('label-for-listening-comprehension-answer-input')
  .text("Kolejna część zdania")
  .prop("placeholder", "Kolejna część zdania")
  .appendTo(secondEnglishPhrase)

  const secondinputForEnglishPhrase = document.createElement('input')
  $(secondinputForEnglishPhrase)
  .addClass('form-control listening-comprehension-answer-input')
  .prop("placeholder", "1,2,3")
  .prop("type", "text")
  .prop("id", "whatever")
  .appendTo(secondEnglishPhrase)

  // buttony

  const phraseButtons = document.createElement('div');
  $(phraseButtons)
  .addClass('phrase-buttons')
  .appendTo(sentenceContaner)

  const addPhraseButton = document.createElement('button');
  $(addPhraseButton)
  .addClass("btn btn-default add-phrase-button")
  .prop("type", "submit")
  .text("Dodaj frazę")
  .appendTo(phraseButtons)

  const removePhraseButton = document.createElement('button');
  $(removePhraseButton)
  .addClass("btn btn-default remove-phrase-button")
  .text("Usuń frazę")
  .appendTo(phraseButtons)

  // kolekcje dla fraz po polsku
  // 1 pole
  const polishSentenceExample = document.createElement('div');
  $(polishSentenceExample)
  .addClass('polish-sentence-example')
  .appendTo(sentenceContaner)

  const polishPhrase = document.createElement("div");
  $(polishPhrase)
  .addClass('form-group polish-phrase')
  .appendTo(polishSentenceExample)

  const labelForPolishPhrase = document.createElement('label')
  $(labelForPolishPhrase)
  .text("Kolejna część zdania")
  .appendTo(polishPhrase)

  const inputForPolishPhrase = document.createElement('input');
  $(inputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .appendTo(polishPhrase)

  // 2 pole

  const secondPolishPhrase = document.createElement("div");
  $(secondPolishPhrase)
  .addClass('form-group polish-phrase another-line')
  .appendTo(polishSentenceExample)

  const secondLabelForPolishPhrase = document.createElement("label");
  $(secondLabelForPolishPhrase)
  .addClass('label-for-polish-phrase')
  .text("Kolejna część zdania")
  .appendTo(secondPolishPhrase)

  const secondInputForPolishPhrase = document.createElement("input");
  $(secondInputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .appendTo(secondPolishPhrase)

  // 3 pole
  const thirdPolishPhrase = document.createElement("div");
  $(thirdPolishPhrase)
  .addClass('form-group polish-phrase')
  .appendTo(polishSentenceExample)

  const thirdLabelForPolishPhrase = document.createElement("label");
  $(thirdLabelForPolishPhrase)
  .addClass('label-for-polish-phrase')
  .text("Kolejna część zdania")
  .appendTo(thirdPolishPhrase)

  const thirdInputForPolishPhrase = document.createElement("input");
  $(thirdInputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .appendTo(thirdPolishPhrase)


  const parentButton = currentButton.parent();
  const sentenceButtons = document.createElement('div');
  const addSentenceButton = document.createElement("button");
  const removeSentenceButton = document.createElement("button");

  if(boolean) {
    $(sentenceButtons)
    .addClass("sentence-buttons")
    .appendTo(oneWordSentencesContainer)

    $(addSentenceButton)
    .addClass("btn btn-default add-sentence-button")
    .prop("type", "submit")
    .text("Dodaj kolejny przykład")
    .appendTo(sentenceButtons)

    $(removeSentenceButton)
    .addClass("btn btn-default remove-sentence-button")
    .text("Usuń ostatni przykład")
    .appendTo(sentenceButtons)

    parentButton.before(oneWordSentencesContainer);
  } else {
    parentButton.before(sentenceContaner);
  }
}

//funkcja do tworzenia minimalnej ilości kolekcji przykładów dla słów na starcie
export function examplesColectionCreatingWithMinNumber (number) {
  logger("info", "examplesColectionCreatingWithMinNumber");

  for(let i = 0; i <= MIN_WORDS; i++) {
    for(let j = 0; j <= number; j++) {
      let currentButton = $('.words-container').children().eq(i).find(".add-examples-button")
      examplesColectionCreating(currentButton)
    }
  }
}

// funkcja do tworzenia kolekcji dla słów
export function examplesColectionCreating(currentButton, boolean, extra) {
  logger("info", "examplesColectionCreating");

  const wordsExamples = document.createElement('div');
  $(wordsExamples)
  .addClass('words-examples')

  // objekt dla wyrazów angielskich
  const englishWordExample = document.createElement("div");
  $(englishWordExample)
  .addClass('wrap-input100 english-word-example my-width first-example-bg')
  .appendTo(wordsExamples)

  const englishWordExampleLabel = document.createElement("span");
  $(englishWordExampleLabel)
  .addClass("control-label english-word-example-label label-input100")
  .prop("for", "")
  .appendTo(englishWordExample)

  const englishColSm10 = document.createElement("div");
  $(englishColSm10)
  .addClass('col-sm-10')
  .appendTo(englishWordExample)

  const englishInput = document.createElement("input");
  $(englishInput)
  .prop("type", "text")
  .addClass("input100 english-word-example-input ")
  .prop("id", '')
  .prop("placeholder", "There is no such lesson like you said before")
  .prop("name", "")
  .appendTo(englishColSm10)

  // objekt dla wyrazów polskich
  const polishWordExample = document.createElement("div");
  $(polishWordExample)
  .addClass('wrap-input100 polish-word-example bg1 my-width')
  .appendTo(wordsExamples)

  const polishWordExampleLabel = document.createElement("span");
  $(polishWordExampleLabel)
  .addClass("control-label polish-word-example-label label-input100")
  .prop("for", "")
  .appendTo(polishWordExample)

  const polishColSm10 = document.createElement("div");
  $(polishColSm10)
  .addClass('col-sm-10')
  .appendTo(polishWordExample)

  const polishInput = document.createElement("input");
  $(polishInput)
  .prop("type", "text")
  .addClass("polish-word-example-input input100")
  .prop("id", '')
  .prop("placeholder", "Nie ma takiej lekcji jak mówiłeś wcześniej")
  .prop("name", "")
  .appendTo(polishColSm10)

  // kolekcje dla pytania o część mowy
  const partOfTheSpeech = document.createElement('div');
  $(partOfTheSpeech)
  .addClass("form-group part-of-the-speech input100-select wrap-input100 bg1")
  .appendTo(wordsExamples)

  const partOfTheSpeechLabel = document.createElement("span");
  $(partOfTheSpeechLabel)
  .addClass("control-label part-of-the-speech-label label-input100")
  .text("Jaka to część mowy.. (Rzeczownik, Przymiotnik..?)")
  .appendTo(partOfTheSpeech)

  const select = document.createElement("select");
  $(select)
  .addClass("part-of-the-speech-select")
  .appendTo(partOfTheSpeech)

  const option = document.createElement("option")
  $(option)
  .text("")
  .appendTo(select)

  const option1 = document.createElement("option")
  $(option1)
  .text("Rzeczownik")
  .appendTo(select)

  const option2 = document.createElement("option")
  $(option2)
  .text("Czasownik")
  .appendTo(select)

  const option3 = document.createElement("option")
  $(option3)
  .text("Przymiotnik")
  .appendTo(select)

  const option4 = document.createElement("option")
  $(option4)
  .text("Przysłówek")
  .appendTo(select)

  const option5 = document.createElement("option")
  $(option5)
  .text("Przyimek")
  .appendTo(select)

  const option6 = document.createElement("option")
  $(option6)
  .text("Spójnik")
  .appendTo(select)

  const option7 = document.createElement("option")
  $(option7)
  .text("Wykrzyknik")
  .appendTo(select)

  const option8 = document.createElement("option")
  $(option8)
  .text("Popularna fraza")
  .appendTo(select)

  // kolekcje dla pytań o polskie znaczenie w danym przykładzie
  const polishWordExplanation = document.createElement("div");
  $(polishWordExplanation)
  .addClass('wrap-input100 bg1 my-width polish-word-explanation')
  .appendTo(wordsExamples)

  const polishWordExplanationLabel = document.createElement("span")
  $(polishWordExplanationLabel)
  .addClass('control-label label-input100 polish-word-explanation-label')
  .appendTo(polishWordExplanation)

  const polishWordExplanationColSm10 = document.createElement("div")
  $(polishWordExplanationColSm10)
  .addClass('col-sm-10')
  .appendTo(polishWordExplanation)

  const polishWordExplanationInput = document.createElement("input");
  $(polishWordExplanationInput)
  .addClass('polish-word-explanation-input input100')
  .prop("type", "text")
  .prop("placeholder", "lekcja")
  .appendTo(polishWordExplanationColSm10)


  // kolekcje dla pytań o ciekawostki gramatyczne/ogólne
  const polishFacts = document.createElement("div");
  $(polishFacts)
  .addClass('wrap-input100 my-width bg1 polish-facts')
  .appendTo(wordsExamples)

  const polishFactsLabel = document.createElement("span")
  $(polishFactsLabel)
  .addClass('control-label polish-facts-label label-input100')
  .appendTo(polishFacts)

  const polishFactsColSm10 = document.createElement("div")
  $(polishFactsColSm10)
  .addClass('col-sm-10')
  .appendTo(polishFacts)

  const polishFactsInput = document.createElement("input");
  $(polishFactsInput)
  .addClass('polish-facts-input input100')
  .prop("type", "text")
  .prop("placeholder", "Słówko lesson ma podwójne znaczenie...")
  .appendTo(polishFactsColSm10)

  // switcher
  const switchLabel = document.createElement('label')
  $(switchLabel)
  .addClass("switch")
  .appendTo(wordsExamples)

  const switchInput = document.createElement('input')
  $(switchInput)
  .prop("type", "checkbox")
  .appendTo(switchLabel)

  const switchSpan = document.createElement('span')
  $(switchSpan)
  .addClass('slider round')
  .appendTo(switchLabel)

  // text do switchera

  const switchText = document.createElement('span')
  $(switchText)

  .addClass('switchText')
  .text("Zdjęcie z żarówką?")
  .appendTo(wordsExamples)

  const deleteExtraButton = document.createElement('button')
  $(deleteExtraButton)
  .addClass("btn delete-extra-button")
  .text("Usuń przykład")
  .appendTo(wordsExamples)
  // buttono do extra przykładów

  const extraButton = document.createElement('button')
  $(extraButton)
  .addClass("btn extra-button")
  .text("Dodaj extra przykład")
  .appendTo(wordsExamples)

  // sekcja ze zdjęciami
  const inputProcessContainer = document.createElement('div')
  $(inputProcessContainer)
  .addClass('inputProcessContainer')
  .appendTo(wordsExamples)

  const image = document.createElement("img");
  $(image)
  .prop('type', 'file')
  .addClass('myImage')
  .appendTo(inputProcessContainer)

  const imageInput = document.createElement("input");
  $(imageInput)
  .prop('type', 'file')
  .addClass('imageInput')
  .appendTo(inputProcessContainer)

  const deleteImage = document.createElement("img");
  $(deleteImage)
  .addClass('deletePictureButton')
  .prop('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
  .appendTo(inputProcessContainer)

  if(extra) {
    $(wordsExamples).insertAfter(currentButton)
  } else {
    currentButton.before(wordsExamples);
  }
}

export function rightOrderForSentenceExamplesLabels (el) {
  logger("info", "rightOrderForSentenceExamplesLabels")

  switch(el) {
    case 0:
      return `Pierwszy przykład`;
      break;
    case 1:
      return `Drugi przykład`;
      break;
    case 2:
      return `Trzeci przykład`;
      break;
    case 3:
      return `Czwarty przykład`;
      break;
    case 4:
      return `Piąty przykład`;
      break;
    case 5:
      return `Szósty przykład`;
      break;
    default:
      return "Więcej przykladów już nie podajemy"
  }
}

export function rightOrderForSentenctions (el, result) {
  logger("info", "rightOrderForSentenctions");

  let phrase;
  if(result) {
     phrase = "podpowiedź"

  switch(el) {
    case 0:
      return `Pierwsza ${phrase}`;
      break;
    case 1:
      return `Druga ${phrase}`;
      break;
    case 2:
      return `Trzecia ${phrase}`;
      break;
    case 3:
      return `Czwarta ${phrase}`;
      break;
    case 4:
      return `Piąta ${phrase}`;
      break;
    case 4:
      return `Szósta ${phrase}`;
      break;
    default:
      return "Więcej przykladów już nie podajemy"
    }
  } else {
    switch(el) {
      case 0:
        return `Link do pliku .mp3`;
        break;
      case 1:
        return `Numer do właściwej odpowiedzi (tylko jedna)`;
        break;
      }
  }
}

export function rightOrderForWordsExamples (el, result) {
  logger("info", "rightOrderForWordsExamples");

  let phrase;
  if(result) {
     phrase = "przykład słówka w krótkim zdaniu/popularnej frazie po angielsku"
  } else {
    phrase = "przykład słówka w krótkim zdaniu/popularnej frazie po polsku"
  }

  switch(el) {
    case 0:
      return `Pierwszy ${phrase}`;
      break;
    case 1:
      return `Drugi ${phrase}`;
      break;
    case 2:
      return `Trzeci ${phrase}`;
      break;
    case 3:
      return `Czwarty ${phrase}`;
      break;
    case 4:
      return `Piaty ${phrase}`;
      break;
    case 5:
      return `Szósty ${phrase}`;
      break;
    case 6:
      return `Siódmy ${phrase}`;
      break;
    case 7:
      return `Ósmy ${phrase}`;
      break;
    case 8:
      return `Dziewiąty ${phrase}`;
      break;
    case 9:
      return `Dziesiąty ${phrase}`;
      break;
    default:
      return "Więcej przykladów już nie podajemy"
  }
}

export function rightOrderForWords (el) {
  logger("info", "rightOrderForWords");

  let phrase = "angielskie słówko"

  switch(el) {
    case 0:
      return `Pierwsze ${phrase}`;
      break;
    case 1:
      return `Drugie ${phrase}`;
      break;
    case 2:
      return `Trzecie ${phrase}`;
      break;
    case 3:
      return `Czwarte ${phrase}`;
      break;
    case 4:
      return `Piąte ${phrase}`;
      break;
    case 5:
      return `Szóste ${phrase}`;
      break;
    case 6:
      return `Siódme ${phrase}`;
      break;
    case 7:
      return `Ósme ${phrase}`;
      break;
    case 8:
      return `Dziewiąte ${phrase}`;
      break;
    case 9:
      return `Dziesiąte ${phrase}`;
      break;
    default:
      return "Więcej słówek już nie podajemy"
  }
}

// funkcja do tworzenia kolekcji słów
 export function wordColectionCreating () {
  logger("info", "wordColectionCreating");

  let wordContainer = document.createElement('div');
  $(wordContainer)
  .addClass("word-container")
  .appendTo(".words-container")

  // kolekcje dla angielskiego słowa
  let englishWord = document.createElement("div");
  $(englishWord)
  .addClass("form-group word english-word")
  .appendTo(wordContainer)


  let englishColSm10 = document.createElement("div");
  $(englishColSm10)
  .addClass("col-sm-10 wrap-input100 bg2")
  .css("padding", "15px")
  .appendTo(englishWord)


  let englishLabel = document.createElement('span');
  $(englishLabel)
  .addClass("english-word-label label-input100")
  .prop("for", "")
  .appendTo(englishColSm10)

  let englishInput = document.createElement("input");
  $(englishInput)
  .addClass("english-word-input input100")
  .prop("type", "text")
  .prop("id", "")
  .prop("placeholder", "lesson ")
  .prop("name", "")
  .appendTo(englishColSm10)

  // kontener dla przykładów

  let examplesContainer = document.createElement("div");
  $(examplesContainer)
  .addClass("examples-container")
  .appendTo(englishWord)

  // przyciski do dodawania przykladów
  let addExamplesButton = document.createElement("button");
  $(addExamplesButton)
  .addClass("btn btn-default add-examples-button")
  .prop("name", "submit")
  .prop("type", "submit")
  .text("Dodaj kolejny przykład")
  .appendTo(examplesContainer)

  //przycisk do usuwanie przykładów
  let removeExamplesButton = document.createElement("button");
  $(removeExamplesButton)
  .addClass("btn btn-default remove-examples-button")
  .prop("name", "submit")
  .prop("type", "submit")
  .text("Usuń przykład")
  .appendTo(examplesContainer)
}

function optionsOnStart () {
  logger("info", "optionsOnStart");

  $('.words-container').find('.word-container').each(function(i, el) {
    $(this).find('.words-examples').each(function (i, el2) {
      if($(el2).find("option:selected").val() === "Popularna fraza") {
        $(el2).children(".polish-word-explanation").hide()
      } else {
        $(el2).children(".polish-word-explanation").show()
      }
    })
  })
}

function removeSimplePhrase(currentButton) {
  logger("info", "removeSimplePhrase");

  const $currentPolishCollection = currentButton.parent().siblings('.polish-sentence-example');
  const $lastPolishPhrase = $currentPolishCollection.find('.polish-phrase').last()
  $lastPolishPhrase.remove();
}

function disableRemoveButton (i, min, data) {
  logger("info", "disableRemoveButton")

  if (data) {
    if(i < min) {
      $(data).prop("disabled", true);
    } else {
      $(data).prop("disabled", false);
    }
  }
}

// funkcja do tworzenia kolekcji min ilości słów na starcie
 function wordColectionCreatingWithMinNumber (number) {
  logger("info", "wordColectionCreatingWithMinNumber");

  for(let i = 0; i <= number; i++) {
    wordColectionCreating();
  }
}

function findingChanging () {
  logger("info", "findingChanging")

  $('.words-container').find('.english-word-example-input').each(function(i, el) {
    const indexOfInput = $(this).parent().parent().parent().attr("id");
    const wordsExample = $(this);
    const input = $(this).val()
    automatedChallengeCollectionCreating(indexOfInput, input, true);
    dataEnteringForChallenge();
  })

  $('.words-container').find('.polish-word-example-input').each(function(i, el) {
    const indexOfInput = $(this).parent().parent().parent().attr("id");
    const wordsExample = $(this);
    const input = $(this).val()
    automatedChallengeCollectionCreating(indexOfInput, input);
    dataEnteringForChallenge();
  })
}

function startFindingChanges() {
  logger("info", "startFindingChanges");

  setInterval(function() {
    findingChanging()
  }, 12000)
}

// startFindingChanges();
// dataEnteringForWords();
