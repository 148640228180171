import { checkingUser, ShowMessage, 
          Token }                                     from "./utility";
import logger                                         from './config/logger';

const $usersLessonsButton = $('#usersLessonsButton');

$usersLessonsButton.on('click', async function () {
  try {
    logger("info", "$usersLessonsButton - fetching lessons");

    const user = checkingUser();
    const lessons = await fetchingListOfLessons(user)
    fillingTheTable(lessons)
  } catch(error) {
    logger("error", error);
  }
})


async function fetchingListOfLessons(user) {
  logger("info", "fetchingListOfLessons");

  const errorMessage = "there was an error while fetching list of lessons";
  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(process.env.NO_TOKEN_MESSAGE, target);

  return new Promise((resolve, reject) => {
    $.ajax({
      method: 'GET',
      url: `${process.env.API_GET_LIST_OF_LESSONS}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Barear ${idToken}`
        }
    })
    .done(response => {
      logger("info", response, "fetchingListOfLessons response");
      if(response.statusCode === 200) {
        const parsedResponse = JSON.parse(response.body);
        return resolve(parsedResponse.data);
      }
      reject(response)
    })
    .fail(error => {
      console.log("error", error);
      if(error.status === 401) {
        return ShowMessage.popupTextMessageForSaveButtons(process.env.NO_AUTH_MESSAGE, this);
      }
      ShowMessage.popupTextMessageForSaveButtons(errorMessage, this);
      reject(error);
    })
  })
}

function compare( a, b ) {
  logger("info", "compare");

  if ( a.category < b.category ) {
    return -1;
  }
  if ( a.category > b.category ){
    return 1;
  }
  return 0;
}

function divideFetchingDataIntoCourse(fetchingData) {
  logger("info", "divideFetchingDataIntoCourse");

  const arrayOfCourses = [];
  const courses = {};
  fetchingData.forEach((el,i) => {
    if(arrayOfCourses.indexOf(el.course) === -1) {
      arrayOfCourses.push(el.course)
      courses[el.course] = [];
      courses[el.course].push(el)
    } else {
      courses[el.course].push(el)
    }
  })

  return courses;
}

function fillingTheTable(data) {
  logger("info", "fillingTheTable");

  const $userList = $('.lessons-list');
  $userList.empty();
  const courses = divideFetchingDataIntoCourse(data)
  for(const course in courses) {
    const currentCourse = courses[course];
    currentCourse.sort(compare);
    currentCourse.forEach(function (item, j) {
      if(j === 0) {
        const div1 = document.createElement('div');
        $(div1)
        .addClass("begining-of-new-course")
        .appendTo($userList)

        const nameOfCourse = document.createElement('div');
        $(nameOfCourse)
        const titleOfCategory = document.createElement("p");
        $(titleOfCategory)
        .text(`Kurs: `)
        .appendTo(div1)

        const contentOfCategory = document.createElement("p");
        $(contentOfCategory)
        .text(`${item.course}`)
        .addClass("bold-class")
        .appendTo(div1)
      }

      const lessonAndDelete = document.createElement('div');
      $(lessonAndDelete)
      .addClass("lesson-and-delete")
      .appendTo($userList)

      const div = document.createElement('div');
      $(div)
      .addClass("one-line-lesson")
      .data("course", `${item.course}`)
      .data("category", `${item.category}`)
      .data("lesson", `${item.lesson}`)
      .prop("pointer", 'cursor')

      // .appendTo($userList)
      .appendTo(lessonAndDelete)
      const p = document.createElement("p")
      $(p)
      .text(`Kategoria: `)
      .appendTo(div)

      const p2 = document.createElement("p")
      $(p2)
      .text(`${item.category}  `)
      .addClass("bold-class")
      .appendTo(div)

      const p3 = document.createElement("p")
      $(p3)
      .text(`Lekcja:`)
      .appendTo(div)

      const p4 = document.createElement("p")
      $(p4)
      .text(`${item.lesson}`)
      .addClass("bold-class")
      .appendTo(div)

      const p5 = document.createElement("div")
      $(p5)
      .addClass("popup")
      .css('display', "inline")
      .appendTo(lessonAndDelete)

      const p6 = document.createElement("img")
      $(p6)
      .prop('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
      .addClass("delete-lesson")
      .appendTo(p5)

      const popup = document.createElement("span")
      $(popup)
      .addClass("save-popup-text")
      .appendTo(p5)
    })
  }
}
