import { extractNamesOfCoursesFromDB, extractNameOfCourse,
        populateListOfCoursesInForm, courseStructure }              from "./courses";
import { extractNamesOfCategoriesFromDB, extractNamesOfCategories,
        buildCategoriesElements, populateListOfCategoriesInForm }   from "./categories";
import { extractNamesOfLessonsFromDB, extractNamesOfLessons,
        populateListOfLessonsInForm, buildLessonsElements }         from "./lessons";
import { TargetBasedOnContext, ShowMessage, Token }                                        from "./utility";
import { deleteImage }                                              from "./pictures";
import ValidateStructure                                            from "./utils/validateStructure";
import authorization                                                from './app';
import logger                                                       from "./config/logger";

const structures = (".structures");

async function sendCoursesToDev(courses, categories, lessons, env) {
  logger("info", "sendCoursesToDev");
  const obj = {};
    obj.courses = courses;
    obj.categories = categories;
    obj.lessons = lessons;
    obj.env = env;
  
  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $.ajax({
    url: process.env.API_DEV_STRUCTURE,
    data: JSON.stringify(obj),
    method: "post",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "sendCoursesToDev response object");
      ShowMessage.popupTextMessageForSaveButtons(response, "structure");
      if(response.statusCode === 200) {
        ShowMessage.popupTextMessageForEnvButtons("test", "Teraz może testować aplikcję", "structure");
        const parsedResponse = JSON.parse(response.body);
        return resolve(parsedResponse.data);
      }
      ShowMessage.popupTextMessageForEnvButtons("test", response, "structure");
      reject(response)
    })
    .catch((error) => {
      logger("error", error, "sendCoursesToDev error");

      const messageToUser = "Wystąpił błąd podczas zapisywania danych bo bazy testowej";
      resolve(messageToUser)
    })
  })
}


export async function saveStructure(context, isValidating, env) {
  logger("info", "saveStructure");

  let messageToUser;
  let target;
  return new Promise(async (resolve, reject) => {
    const entireCourseData = [];
    const { number: numberOfCourses, structures } = checkNumberOFCourses();
    const listOfNamesOfCourses = [];
    const listOfNamesOfCategories = [];
    const listOfDescriptionsOfCategories = [];
    const listOfNamesOfLessons = [];
    const listOfDescriptionsOfLessons = [];
    const courses = {};
    courses.list = [];
    let categories = [];
    let lessons = [];
    const validator = new ValidateStructure(structures, numberOfCourses);
    validator.validateNameOfCourses();
    if(validator.comments.length === 0) {
      for(let i = 0; i < numberOfCourses; i++) {
        entireCourseData[i] = {};
        const listOfComments = [];
        const structure = structures[i];
        const { entireCourse: resultWithCourses, course } = saveCourse(entireCourseData[i], structure);
          courses.list.push(course);
          const nameOfCourse = extractNameOfCourse(structure);
          listOfNamesOfCourses.push(nameOfCourse);
          const { arrayOfCategoriesOfNames, arrayOfDescriptionsOfNames } = extractNamesOfCategories(structure);
          listOfNamesOfCategories.push(...arrayOfCategoriesOfNames);
          listOfDescriptionsOfCategories.push(...arrayOfDescriptionsOfNames);
          const { entireCourse: resultWithCategories, categories: listOfCategoriesForSpecificCourse } = saveCategories(resultWithCourses, nameOfCourse, structure);
          categories.push(listOfCategoriesForSpecificCourse)
          const { arrayOfNamesOfLessons, arrayOfDescriptionsOfLessons } = extractNamesOfLessons(structure);
          listOfNamesOfLessons.push(...arrayOfNamesOfLessons);
          listOfDescriptionsOfLessons.push(...arrayOfDescriptionsOfLessons);
          const { entireCourse: resultWithLessons, lessons: listOfLessonsForAllCategoriesForSpecificCourse } = saveLessons(resultWithCategories, arrayOfCategoriesOfNames, structure, nameOfCourse);
          lessons.push(listOfLessonsForAllCategoriesForSpecificCourse)
      }
      populateListOfCoursesInForm(listOfNamesOfCourses);
      populateListOfCategoriesInForm(listOfNamesOfCategories, listOfDescriptionsOfCategories);
      populateListOfLessonsInForm(listOfNamesOfLessons, listOfDescriptionsOfLessons);
      console.log("entireCourseData!!!!!!!!!!!!", entireCourseData)
      messageToUser = await sendEntireCourseObject(entireCourseData);
      // ShowMessage.popupTextMessageForSaveButtons(messageToUser, context);
      if(isValidating) {
        validator.validateImageInCourseField();
        validator.validateNumberOfCategoriesPerCourse();
        validator.validateAllFieldsInCategoryForm();
        validator.validateNumberOfLessonsPerCategory();
        validator.validateAllFieldsInLessonForm();
        if(validator.comments.length === 0) {
          await sendCoursesToDev(courses, categories, lessons, env);
          const message = `Structure was sent to ${env} environment`;
          resolve(message);
        } else {
          messageToUser = validator.comments;
          validator.comments = [];
          resolve(messageToUser);
        }
      }
    } else {
      console.log("elese");
      messageToUser = validator.comments
      validator.comments = [];
      resolve(messageToUser);
    }
  })
}

$(structures).on("click", ".add-structure-button", function () {
  logger("info",  "add-structure-button on click")
  try {
    buildEmptyStructure($(this));
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wysąpił błąd podczas tworzenia struktury";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

$(structures).on("click", ".remove-structure-button", async function () {
  try {
    logger("info", ".remove-structure-button on click");

    const target = TargetBasedOnContext.getGeneralTarget(this);
    const imagesToRemove = $(this).parents(".structure-section").siblings('.structure').last().find(".deletePictureButton").each(async (index, el) => {
      return await deleteImage(el, target);
    });
    await Promise.all(imagesToRemove);
    const structure = $(this).parents(".structure-section").siblings('.structure').last();
    structure.remove();
    saveStructure(this, false);
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas usuwania struktury";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

function buildEmptyStructure(currentButton, el={}) {
    logger("info", "buildEmptyStructure")

    const structure = document.createElement('div');
    $(structure)
    .addClass("structure")

    //  course
    const courseSection = document.createElement('div');
    $(courseSection)
    .addClass("course-section")
    .appendTo(structure)

    const container = document.createElement("div");
    $(container)
    .addClass("container")
    .appendTo(courseSection)

    const courseAdding = document.createElement('div');
    $(courseAdding)
    .addClass('course-adding')
    .appendTo(container)

    const courseForm = document.createElement("div")
    $(courseForm)
    .addClass("course-form")
    .appendTo(courseAdding)


    const courseElement = document.createElement('div');
    $(courseElement)
    .addClass("course-element course-adding-form")
    .appendTo(courseForm)

    const courseNameForm = document.createElement('div');
    $(courseNameForm)
    .addClass("form-group course-name-form input100-select wrap-input100 bg1")
    .appendTo(courseElement)

    const labelForEnglishName = document.createElement('span')
    $(labelForEnglishName)
    .addClass('course-adding-label label-input100')
    .text('Nazwa kursu po angielsku - musi posiadać słowo "course"')
    .appendTo(courseNameForm)

    const inputEnglishName = document.createElement('input')
    if(el.englishCourseName) {
      $(inputEnglishName)
      .addClass('course-name-input input100')
      .prop("type", "text")
      .val(el.englishCourseName)
      .appendTo(courseNameForm)
    } else {
      $(inputEnglishName)
      .addClass('course-name-input input100')
      .prop("type", "text")
      .val("")
      .appendTo(courseNameForm)
    }
    const descriptionForm = document.createElement('div');
    $(descriptionForm)
    .addClass("form-group description-course-form input100-select wrap-input100 bg1")
    .appendTo(courseElement)

    const labelForPolishDescription = document.createElement('span')
    $(labelForPolishDescription)
    .addClass('label-input100')
    .text('Opis kursu po polsku')
    .appendTo(descriptionForm)

    const inputForPolishDescription = document.createElement('input')
    if(el.polishCourseName) {
      $(inputForPolishDescription)
      .addClass('course-description-input input100')
      .prop("type", "text")
      .val(el.polishCourseName)
      .appendTo(descriptionForm)
    } else {
      $(inputForPolishDescription)
      .addClass('course-description-input input100')
      .prop("type", "text")
      .val("")
      .appendTo(descriptionForm)
    }
    const inputProcessContainer = document.createElement('div')
    $(inputProcessContainer)
    .addClass('inputProcessContainer')
    .appendTo(courseElement)

    const image = document.createElement('img')
    if(el.image) {
      $(image)
      .addClass('myImage course-image')
      .attr('src', el.image)
      .css('display', 'inline')
      .appendTo(inputProcessContainer)
    } else {
      $(image)
      .addClass('myImage course-image')
      .css('display', 'none')
      .appendTo(inputProcessContainer)
    }

    const fileInput = document.createElement('input')
    $(fileInput)
    .addClass('imageInput')
    .data("target", "course")
    .prop('type', 'file')
    .attr('src', '')
    .appendTo(inputProcessContainer)

    const imageForDelate = document.createElement('img')
    if(el.image) {
      $(imageForDelate)
      .addClass('deletePictureButton')
      .css('display', 'block')
      .attr('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
      .appendTo(inputProcessContainer)
    } else {
      $(imageForDelate)
      .addClass('deletePictureButton')
      .css('display', 'none')
      .attr('src', 'https://english-project.s3.eu-central-1.amazonaws.com/icons/universal/criss-cross.png')
      .appendTo(inputProcessContainer)
    }

    // category

    const categories = document.createElement("div");
    $(categories)
    .addClass("container categories")
    .appendTo(structure)

    const formInline = document.createElement("div");
    $(formInline)
    .addClass("form-inline challenge category-form")
    .appendTo(categories)

    const categoryInstruction = document.createElement("div");
    $(categoryInstruction)
    .addClass("category-instruction")
    .appendTo(formInline)

    const categoryRows = document.createElement("div");
    $(categoryRows)
    .addClass("category-rows scroll")
    .appendTo(formInline)

    const categoryButtons = document.createElement("div")
    $(categoryButtons)
    .addClass("category-buttons")
    .appendTo(categories)

    const addCategoryButton = document.createElement('button')
    $(addCategoryButton)
    .addClass("btn btn-default add-categories-button")
    .text("Dodaj kategorię")
    .appendTo(categoryButtons)

    const removeCategoryButton = document.createElement('button')
    $(removeCategoryButton)
    .addClass("btn btn-default remove-categories-button")
    .text("Usuń ostatnią kategorię")
    .appendTo(categoryButtons)

    const buttonSection = currentButton.parents(".structure-section");
    buttonSection.before(structure)

}

$(".save-structure-button").on('click', async function () {
  try {
    logger("info", "click save-structure-button");
    const message = await saveStructure(this, false, "save");
    ShowMessage.popupTextMessageForSaveButtons(message, this)
  } catch(e) {
    logger("error", e);
    const message = "Niestety ale wystąpił błąd podczas zapisywania struktury";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})


$(".structures .verification-structure-button").on('click', async function () {
  try {
    logger("info", "click verification-structure-button");

    const message = await saveStructure(this, true, "test");
    ShowMessage.popupTextMessageForEnvButtons("test", message, this);
  } catch(e) {
    logger("error", e);
    console.log("error", e);
    const message = "Niestety ale wystąpił błąd podczas zapisywania struktury do testowania";
    // const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas zapisywania struktury do testowania";
    ShowMessage.popupTextMessageForSaveButtons("test", message, this);
  }
})

$(".structures .public-structure-button").on('click', async function () {
  try {
    logger("info", "click verification-structure-button");

    const message = await saveStructure(this, true, "production");
    ShowMessage.popupTextMessageForEnvButtons("test", message, this);
  } catch(e) {
    logger("error", e);
    console.log("error", e);
    const message = "Niestety ale wystąpił błąd podczas zapisywania struktury do testowania";
    // const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas zapisywania struktury do testowania";
    ShowMessage.popupTextMessageForSaveButtons("test", message, this);
  }
})



function saveCourse(obj, structure) {
  logger("info", "saveCourse");

  const result = {};
  const course = {};
  const englishName = $(structure).find(".course-name-input");

  if($(structure).find(".course-name-input").val()) {
    const name = $(structure).find(".course-name-input").val();
    course.title = name;
    course.alt = name;
    obj.englishCourseName = name;
  }

  if($(structure).find(".course-description-input").val()) {
    const description = $(structure).find(".course-description-input").val();
    course.description = description;
    obj.polishCourseName = $(structure).find(".course-description-input").val();
  }

  if($(structure).find("img.myImage").attr('src')) {
    const image = $(structure).find("img.myImage").attr('src');
    course.image = image;
    obj.image = image;
  }

  result.entireCourse = obj;
  result.course = course;
  return result;
}

export function checkNumberOFCourses() {
  logger("info", "checkNumberOFCourses");

  const obj = {};
  const structuresArray = [];
  let number;
  let structure = false;
  $(structures).find(".structure").each((i, el) => {
    structure = true;
    structuresArray.push(el);
    number=+i
  })

  if(structure) {
    const finaleNumber = number + 1
    obj.number = finaleNumber;
    obj.structures = structuresArray;
  } else {
    obj.number = 0;
    obj.structures = [];
  }

  return obj;
}

function saveCategories(obj, nameOfCourse, structure, categories) {
    logger("info", "saveCategories");

    obj.categories = {};
    obj.categories.list = [];
    const result = {};
    let oneCategory = {};
    const categoriesRows = $(structure).find(".category-rows");
    $(categoriesRows).find(".one-category").each((i, category) => {
      if($(category).find(".english-category-input").val()) {
        const name = $(category).find(".english-category-input").val();
        oneCategory.title = name;
        oneCategory.alt = name;
      }
      if($(category).find(".polish-category-input").val()) {
        oneCategory.description = $(category).find(".polish-category-input").val();
      }
      if($(category).find("img.category-image").attr('src')) {
        oneCategory.image = $(category).find("img.category-image").attr('src');
      }
      obj.categories.list.push(oneCategory);
      oneCategory = {}
    })
    result.categories = {};
    result.categories.name = nameOfCourse
    result.categories.list = obj.categories.list;
    result.entireCourse = obj;

    return result;
}

function saveLessons(obj, categories, structure, nameOfCourse) {
    logger("info", "saveLessons")

    const allCategories = [];
    const result = {};
    $(structure).find(".one-category").each((i, category) => {
      if($(category).find(".english-category-input").val()) {
        let oneLesson = {};
        let oneCategory = {};
        oneCategory.list = [];
        const englishCategoryName = $(category).find(".english-category-input").val();
        const translatedCategoryName = $(category).find(".polish-category-input").val();
        obj.categories[englishCategoryName] = {};
        obj.categories[englishCategoryName].list = [];
        let hasOwnProperty = false;
        $(category).find(".one-lesson").each((n, lesson) => {
          if($(lesson).find(".english-lesson-input").val()) {
            const title = $(lesson).find(".english-lesson-input").val();
            oneLesson.title = title;
            oneLesson.alt = title;
          } else {
            oneLesson.title = '';
            oneLesson.alt = '';
          }
          if($(lesson).find(".polish-lesson-input").val()) {
            oneLesson.description = $(lesson).find(".polish-lesson-input").val();
          } else {
            oneLesson.description = '';
          }
          if($(lesson).find("img.myImage").attr('src')) {
            oneLesson.image = $(lesson).find("img.myImage").attr('src');
          } else {
            oneLesson.image = '';
          }
          for(let prop in oneLesson) {
            if (oneLesson.hasOwnProperty(prop)) {
              hasOwnProperty = true;
            }
          }
          if(hasOwnProperty) {
            obj.categories[englishCategoryName].list.push(oneLesson);
            hasOwnProperty = false;
            oneCategory.name = categories[i];
            oneCategory.course = nameOfCourse;
            oneCategory.translatedCategoryName = translatedCategoryName;
            oneCategory.list.push(oneLesson)
            oneLesson = {};
          }
        })
        allCategories.push(oneCategory);
        oneCategory = [];
      } else {
        const message = "Uzupełnij nazwę kategorii";
        ShowMessage.popupTextMessageForSaveButtons(message, this);
      }
    })
    result.lessons = allCategories;
    result.entireCourse = obj;

    return result;
}



async function sendEntireCourseObject(structure) {
  logger("info", "sendEntireCourseObject");

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $.ajax({
    url: process.env.API_SAVE_STRUCTURE,
    data: JSON.stringify({structure}),
    method: "post",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, 'sendEntireCourseObject response object');
      ShowMessage.popupTextMessageForSaveButtons(response, "structure-icons");
      if(response.statusCode === 200) {
        const parsedResponse = JSON.parse(response.body);
        return resolve(parsedResponse.data);
      }
      reject(response)
    })
    .catch((message) => {
      reject(message)
    })
  })
}

export async function getElements() {
  try {
    logger("info", "getElements");

    const result = await fetchingElements();
    console.log("result po fetcging!!!!!!!!!!!!!!!!", result);
    if(result && result.length > 0) {
      const listOfNamesOfCourses = [];
      const listOfNamesOfCategories = [];
      const listOfDescriptionsOfCategories = [];
      const listOfNamesOfLessons = [];
      const listOfDescriptionsOfLessons = [];
      const addStructureButton = $(".add-structure-button");
      result.forEach((el) => {
        buildEmptyStructure(addStructureButton);
        const { course, name:nameOfCourse } = extractNamesOfCoursesFromDB(el);
        listOfNamesOfCourses.push(nameOfCourse);
        courseStructure(course);
        const { categories, arrayOfCategoriesOfNames, arrayOfDescriptionsOfNames } = extractNamesOfCategoriesFromDB(el);
        listOfNamesOfCategories.push(...arrayOfCategoriesOfNames);
        listOfDescriptionsOfCategories.push(...arrayOfDescriptionsOfNames);
        buildCategoriesElements(null, categories);
        const { arrayOfNamesOfLessons, arrayOfDescriptionsOfLessons } = extractNamesOfLessonsFromDB(el);
        listOfNamesOfLessons.push(...arrayOfNamesOfLessons);
        listOfDescriptionsOfLessons.push(...arrayOfDescriptionsOfLessons);
        buildLessonsElements(el);
      })
      populateListOfCoursesInForm(listOfNamesOfCourses);
      populateListOfCategoriesInForm(listOfNamesOfCategories, listOfDescriptionsOfCategories);
      populateListOfLessonsInForm(listOfNamesOfLessons, listOfDescriptionsOfLessons);
    }
  } catch(error) {
    logger("error", error, "error");
    const message = (error.answer) ? error.answer : "Niestety ale wystąpił błąd podczas pobierania struktury";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
}


async function fetchingElements () {
  logger("info", "fetchingElements");
  const noTokenResponse = 'There was an error. Try to login again';

  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(noTokenResponse, target);

  return new Promise((resolve, reject) => {
    $.ajax({
    url: process.env.API_GET_STRUCTURE,
    method: "get",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "fetchingElements response object");
      ShowMessage.popupTextMessageForSaveButtons(response, "structure-icons");
      if(response.statusCode === 200) {
        const parsedResponse = JSON.parse(response.body);
        return resolve(parsedResponse.data);
      }
      reject(response)
    })
    .catch((res) => {
      if(res.status === 401) {
        ShowMessage.popupTextMessageForSaveButtons("zaloguj sie ponownie", "structure");
      }
      reject(res)
    })
  })
}
