
import {dataEnteringForWords, dataEnteringForChallenge,
        challengeCollectionCreating, chatCollectionCreating,
        sentenceCollectionCreating, addingSimplePhrase,
        examplesColectionCreating, dataEnteringForSentences,
        wordColectionCreating }                                           from './formsUtility'
import { isProductionAvailable, makeProductionButtonVisible }             from './utils/helperFunctions';
import { NoBusinessError, ShowMessage, Token }                            from './utility';
import { encodeString }                                                   from './utils/helperFunctions';
import logger                                                             from './config/logger';

const modal = document.getElementById("myModal");
const btn = document.getElementById("usersLessonsButton");
const span = document.getElementsByClassName("close")[0];
btn.onclick = function() {
  modal.style.display = "block";
}
span.onclick = function() {
  modal.style.display = "none";
}
window.onclick = function(event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
}

$('.lessons-list').on("click", ".one-line-lesson", async function () {
  try {
    logger("info", "click .one-line-lesson");

    const course = $(this).data("course");
    const category = $(this).data("category");
    const lesson = $(this).data("lesson");
    const key = `${course}_${category}_${lesson}`;

    const lessonFromServer = await getLesson(key);
    addingLessonToForm(lessonFromServer);
    const boolean = isProductionAvailable(lessonFromServer);
    makeProductionButtonVisible(boolean);
  } catch(e) {
    logger("error", e);
    const message = (e instanceof NoBusinessError) ? "Przed dokonaniem zmian musisz się zalogować" : "Niestety ale wystąpił błąd podczas dodawania lekcji do formularza";
    ShowMessage.popupTextMessageForSaveButtons(message, this);
  }
})

async function getLesson(key) {
  logger("info", "getLesson");
  
  $('#myModal').css("display", "none");
  const encodedKey = encodeString(key)
  
  const idToken = Token.getIdToken();
  if(!idToken) return ShowMessage.popupTextMessageForSaveButtons(process.env.NO_TOKEN_MESSAGE, target);

  return new Promise((resolve, reject) => {
    $.ajax({
    url: `${process.env.API_GET_LESSON}${encodedKey}`,
    method: "get",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Barear ${idToken}`
      }
    })
    .done((response) => {
      logger("info", response, "getLesson response object");
      if(response.statusCode === 200) {
        ShowMessage.popupTextMessageForSaveButtons(response.answer, "lesson");
        const parsedResponse = JSON.parse(response.body);
        return resolve(parsedResponse.data);
      }
      ShowMessage.popupTextMessageForSaveButtons(response.answer, "lesson");
      reject(response)
    })
    .catch((response) => {
      const lessonErrorMessage = "there was an error while retrieving lesson";
      ShowMessage.popupTextMessageForSaveButtons(lessonErrorMessage, "lesson");
      reject(response)
    })
  })
}

// funkcja dodawania przykladów z serwera
function dataEnteringForWordsFromServer (data, wordsArray) {
  $('.words-container').find('.word-container').each(function(i, el) {
    $(this).find("input.english-word-input").val(wordsArray[i]);
    let examplesForWord;
    if(data.todaysLesson.words[wordsArray[i]].examplesForWord.length > 0) {
      examplesForWord = data.todaysLesson.words[wordsArray[i]].examplesForWord
      $(this).find(".words-examples").each(function (j, el1) {
        if(examplesForWord[j][2]) {
          $(this).find('.english-word-example-input').val(examplesForWord[j][2]);
        }
        if(examplesForWord[j][3]) {
          $(this).find('.polish-word-example-input').val(examplesForWord[j][3]);
        }
        if(examplesForWord[j][1]) {
          $(this).find('.polish-word-explanation-input').val(examplesForWord[j][1]);
        }
        if(examplesForWord[j][0] == "Popularna fraza") {
          $(this).find('.part-of-the-speech-select').val(examplesForWord[j][0]);
          $(this).find('.polish-word-explanation').hide();
        } else if(examplesForWord[j][0]) {
          $(this).find('.part-of-the-speech-select').val(examplesForWord[j][0]);
          $(this).find('.polish-word-explanation').show();
        }
        if(examplesForWord[j][4] != null) {
          $(this).find('.polish-facts-input').val(examplesForWord[j][4]);
        }
        if(examplesForWord[j][5]) {
          $(this).find('img.myImage').attr('src', examplesForWord[j][5]).css('display', 'inline')
          $(this).find('img.deletePictureButton').css('display', 'block')
        }
        if(examplesForWord[j][6]) {
          const input = el1.querySelector('.switch input');
          input.checked = examplesForWord[j][6]
        }
      })
    }
  })
}

// funkcja dodawania przykładów sentencji z serwera
function dataEnteringForSentencesFromServer (data, wordsArray) {
  $('.all-words-sentence-container').find('.one-word-sentences-container').each(function(i, el) {
    const sentenceExamples = data.todaysLesson.words[wordsArray[i]].sentences.length > 0 ? data.todaysLesson.words[wordsArray[i]].sentences : false;
    $(this).children(".sentence-container").each(function (j, ele) {
      if(sentenceExamples) {
        $(this).find(".english-sentence-example").each(function(k, elee) {
            if(sentenceExamples[j]) {
              if(sentenceExamples[j].translated_array.length > 3 ) {
                const numberOfExtraPhrases = sentenceExamples[j].translated_array.length - 3;
                for(let i = 0 ; i < numberOfExtraPhrases; i++) {
                  let currentButton = $(this).siblings(".phrase-buttons").children(".add-phrase-button")
                  addingSimplePhrase(currentButton);
                }
              }
              $(this).find('.listening-comprehension-link-input').each(function (l, el3) {
                $(this).val(sentenceExamples[j].link_to_mp3[l])
              })
              $(this).find('.listening-comprehension-answer-input').each(function (l, el3) {
                $(this).val(sentenceExamples[j].sentence_to_repead[l])
              })
            }
        })
        $(this).find(".polish-sentence-example").each(function(k, elee) {
            if(sentenceExamples[j].translated_array) {
              $(this).find('.polish-phrase-input').each(function (l, el3) {
                $(this).val(sentenceExamples[j].translated_array[l])
            })
          }
        })
      } else {
        $(this).remove()
      }
    })
  })
}
function addingLessonToForm (data) {
  logger("info", "addingLessonToForm");

  // pobieramy nazwy kategorii i lekcji
  $('.course-select').val(data.course);
  $('.english-category-select').val(data.category);
  $('.polish-category-select').val(data.translatedCategory);
  $('.english-lesson-select').val(data.lesson);
  $('.polish-lesson-select').val(data.translatedLesson);
  $('.lesson-english-description-input').val(data.todaysLesson.words.lesson_description);
  $('.lesson-polish-description-input').val(data.todaysLesson.words.lesson_description_translate);
  $('.all-lesson-words-input').val(data.todaysLesson.words.words_to_repeat);
  $('.english-chat-description-input').val(data.todaysLesson.chatDescription);
  $('.polish-chat-description-input').val(data.todaysLesson.translatedChatDescription);
  $('.words-container').find('.word-container').each(function () {
    $(this).remove();
  })

  const wordsArray = data.todaysLesson.words.words_to_repeat;
  const numberOfWords = data.todaysLesson.words.words_to_repeat.length - 1;
  const chatArray = data.todaysLesson.chat;
  const translatedChatArray = data.todaysLesson.translate;
  const challenges = data.todaysLesson.challengeForToday;
  const examplesArray = []
  const sentencesArray = [];

  // dodajemy słówka
  wordColectionCreatingWithMinNumber(numberOfWords);
  dataEnteringForWords()

  // dodajemy dokładną ilość przykladów dla każdego słówka
  for(let i = 0; i < data.todaysLesson.words.words_to_repeat.length; i++) {
    let numberOfExamples = data.todaysLesson.words[wordsArray[i]].examplesForWord.length;
    examplesArray.push(numberOfExamples)
  }

  examplesColectionCreatingWithMinNumberFromServer(data.todaysLesson.words.words_to_repeat.length, examplesArray)
  dataEnteringForWords();
  dataEnteringForWordsFromServer(data, wordsArray);
  dataEnteringForWords();

  $('.all-words-sentence-container').find('.one-word-sentences-container').each(function () {
    $(this).remove();
  })

  //  ćwiczenia z rozumienia ze słuchu
  sentenceCollectionCreatingWithMinNumber(numberOfWords)
  dataEnteringForSentences();


  for(let j = 0; j < data.todaysLesson.words.words_to_repeat.length; j++) {
    const numberOfSentences = data.todaysLesson.words[wordsArray[j]].sentences.length - 1 ? data.todaysLesson.words[wordsArray[j]].sentences.length - 1 : 0
    sentencesArray.push(numberOfSentences);
  }

  sentencesColectionCreatingWithMinNumberFromServer(data.todaysLesson.words.words_to_repeat.length, sentencesArray)
  dataEnteringForSentencesFromServer(data, wordsArray);
  dataEnteringForSentences();

  $('.all-the-rools').find('.one-chat-row').each(function (i) {
      $(this).remove();
  })

  const numberOfDialogs = data.todaysLesson.chat.length /2;
  // tworzenie dialogów chatu
  chatCollectionCreatingFromServer(numberOfDialogs);
  dataEnteringForChatFromServer(chatArray, translatedChatArray);

  // wyzwania
  $('.challenge-rows').find('.one-challenge').each(function (i) {
      $(this).remove();
  });

  const numberOfChallenges = data.todaysLesson.challengeForToday.length /2;

  challengeCollectionCreatingFromServer(numberOfChallenges);
  dataEnteringForChallenge();
  dataEnteringForChallengeFromServer(data, challenges);
}

function dataEnteringForChallengeFromServer (data, challenges) {
  logger("info", "dataEnteringForChallengeFromServer");

  $('.challenge-rows input').each(function(i, el) {
    if(challenges[i]) {
      $(this).val('')
    }
  })
  $('.challenge-rows input').each(function(i, el) {
    if(challenges[i]) {
      $(this).val(challenges[i])
    }
  })
}

function dataEnteringForChatFromServer (chatArray, translatedChatArray) {
  logger("info", "dataEnteringForChatFromServer")
  // usuwamy dane z inputa
  $('.all-the-rools .one-chat-row .english-chat-row input').each(function (i) {
    if(chatArray[i]) {
      $(this).val('')
    }
  })
  $('.all-the-rools .one-chat-row .polish-chat-row input').each(function (i) {
    if(translatedChatArray[i]) {
      $(this).val('')
    }
  })

  $('.all-the-rools .one-chat-row .english-chat-row input').each(function (i) {
    if(chatArray[i]) {
      $(this).val(chatArray[i])
    }
  })
  $('.all-the-rools .one-chat-row .polish-chat-row input').each(function (i) {
    if(translatedChatArray[i]) {
      $(this).val(translatedChatArray[i])
    }
  })
}

function chatCollectionCreatingFromServer(number) {
  logger("info", "chatCollectionCreatingFromServer");

  let currentButton = $('.add-chat-button')
  for(let i = 0; i < number; i++) {
    chatCollectionCreating(currentButton);
  }
}

function challengeCollectionCreatingFromServer (number) {
  logger("info", "chatCollectionCreatingFromServer");

  const currentButton = $('.add-challenge-button')
  for(let i = 0; i < number; i++) {
    challengeCollectionCreating(currentButton);
  }
}


// funkcja do tworzenia kolekcji min ilości sentencji dla jednego słowa
function sentenceCollectionCreatingWithMinNumber (number) {
  logger("info", "sentenceCollectionCreatingWithMinNumber");

  for(let i = 0; i <= number; i++) {
    sentenceCollectionCreatingWithoutButton(true);
  }
}

// funkcja do tworzenia kolekcji min ilości słów na starcie
function wordColectionCreatingWithMinNumber (number) {
  logger("info", "wordColectionCreatingWithMinNumber");

  for(let i = 0; i <= number; i++) {
    wordColectionCreating();
  }
}

//funkcja do odtwarzania ilości przykladów z serwera
function examplesColectionCreatingWithMinNumberFromServer (words, array) {
  logger("info", "examplesColectionCreatingWithMinNumberFromServer");

  for(let i = 0; i <= words; i++) {
    for(let j = 0; j < array[i]; j++) {
      let currentButton = $('.words-container').children().eq(i).find(".add-examples-button")
      examplesColectionCreating(currentButton)
    }
  }
}

//funkcja do odtwarzania ilości sentencji z serwera dla każdego słowa
function sentencesColectionCreatingWithMinNumberFromServer (words, array) {
  logger("info", 'sentencesColectionCreatingWithMinNumberFromServer');

  for(let i = 0; i <= words; i++) {
    // dodajemy tylko gdy są jakieś przykłady
    if(array[i] > 0) {
      for(let j = 0; j < array[i]; j++) {
        let currentButton = $('.all-words-sentence-container').children().eq(i).find(".add-sentence-button")
        sentenceCollectionCreating(currentButton)
      }
    }
  }
}

// funkcja do tworzenia kolekcji sentencji
function sentenceCollectionCreatingWithoutButton (boolean) {
  logger("info", "tworzymy sentencje!!")

  const sentenceContaner = document.createElement('div');
  const oneWordSentencesContainer = document.createElement("div");
  const h3 = document.createElement('h3');

  if(boolean) {
    $(oneWordSentencesContainer)
    .addClass("one-word-sentences-container")

    $(h3)
    .appendTo(oneWordSentencesContainer)

    $(sentenceContaner)
    .addClass("sentence-container")
    .appendTo(oneWordSentencesContainer)
  } else {
    $(sentenceContaner)
    .addClass("sentence-container")
  }

  let p = document.createElement('p');
  $(p)
  .appendTo(sentenceContaner);
  // tutaj dodajemy pola do modułu sluchania. Ale bez przycisków głównych


  const englishSentenceExample = document.createElement('div');
  $(englishSentenceExample)
  .addClass('english-sentence-example')
  .appendTo(sentenceContaner)

  const englishPhrase = document.createElement("div");
  $(englishPhrase)
  .addClass('form-group english-phrase')
  .appendTo(englishSentenceExample)

  const labelForEnglishPhrase = document.createElement('label')
  $(labelForEnglishPhrase)
  .addClass('label-for-listening-comprehension-link-input')
  .text("Kolejna część zdania")
  .prop("placeholder", "https://..")
  .appendTo(englishPhrase)

  const inputForEnglishPhrase = document.createElement('input');
  $(inputForEnglishPhrase)
  .addClass('form-control listening-comprehension-link-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .prop("placeholder", "")
  .appendTo(englishPhrase)

  // 2 pole

  const secondEnglishPhrase = document.createElement('div')
  $(secondEnglishPhrase)
  .addClass('form-group english-phrase another-line')
  .appendTo(englishSentenceExample)

  const secondlabelForEnglishPhrase = document.createElement("label")
  $(secondlabelForEnglishPhrase)
  .addClass('label-for-listening-comprehension-answer-input')
  .text("Kolejna część zdania")
  .prop("placeholder", "Kolejna część zdania")
  .appendTo(secondEnglishPhrase)

  const secondinputForEnglishPhrase = document.createElement('input')
  $(secondinputForEnglishPhrase)
  .addClass('form-control listening-comprehension-answer-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .prop("placeholder", "np. Zjedz banana")
  .appendTo(secondEnglishPhrase)

  // buttony

  const phraseButtons = document.createElement('div');
  $(phraseButtons)
  .addClass('phrase-buttons')
  .appendTo(sentenceContaner)

  const addPhraseButton = document.createElement('button');
  $(addPhraseButton)
  .addClass("btn btn-default add-phrase-button")
  .prop("type", "submit")
  .text("Dodaj frazę")
  .appendTo(phraseButtons)

  const removePhraseButton = document.createElement('button');
  $(removePhraseButton)
  .addClass("btn btn-default remove-phrase-button")
  .text("Usuń frazę")
  .appendTo(phraseButtons)

  // kolekcje dla fraz po polsku

  const polishSentenceExample = document.createElement('div');
  $(polishSentenceExample)
  .addClass('polish-sentence-example')
  .appendTo(sentenceContaner)

  const polishPhrase = document.createElement("div");
  $(polishPhrase)
  .addClass('form-group polish-phrase')
  .appendTo(polishSentenceExample)

  const labelForPolishPhrase = document.createElement('label')
  $(labelForPolishPhrase)
  .text("Kolejna część zdania")
  .appendTo(polishPhrase)

  const inputForPolishPhrase = document.createElement('input');
  $(inputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .prop("placeholder", "np. Zjedz banana")
  .appendTo(polishPhrase)

  // 2 pole

  const secondPolishPhrase = document.createElement("div");
  $(secondPolishPhrase)
  .addClass('form-group polish-phrase another-line')
  .appendTo(polishSentenceExample)

  const secondLabelForPolishPhrase = document.createElement("label");
  $(secondLabelForPolishPhrase)
  .addClass('label-for-polish-phrase')
  .text("Kolejna część zdania")
  .appendTo(secondPolishPhrase)

  const secondInputForPolishPhrase = document.createElement("input");
  $(secondInputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .prop("placeholder", "np. Zjedz banana")
  .appendTo(secondPolishPhrase)

  // 3 pole

  const thirdPolishPhrase = document.createElement("div");
  $(thirdPolishPhrase)
  .addClass('form-group polish-phrase another-line')
  .appendTo(polishSentenceExample)

  const thirdLabelForPolishPhrase = document.createElement("label");
  $(thirdLabelForPolishPhrase)
  .addClass('label-for-polish-phrase')
  .text("Kolejna część zdania")
  .appendTo(thirdPolishPhrase)

  const thirdInputForPolishPhrase = document.createElement("input");
  $(thirdInputForPolishPhrase)
  .addClass('form-control polish-phrase-input')
  .prop("type", "text")
  .prop("id", "whatever")
  .prop("placeholder", "np. Zjedz banana")
  .appendTo(thirdPolishPhrase)

  const parentButton = $('.all-word-sentence-button');
  const sentenceButtons = document.createElement('div');
  const addSentenceButton = document.createElement("button");
  const removeSentenceButton = document.createElement("button");

  if(boolean) {
    $(sentenceButtons)
    .addClass("sentence-buttons")
    .appendTo(oneWordSentencesContainer)

    $(addSentenceButton)
    .addClass("btn btn-default add-sentence-button")
    .prop("type", "submit")
    .text("Dodaj kolejny przykład")
    .appendTo(sentenceButtons)

    $(removeSentenceButton)
    .addClass("btn btn-default remove-sentence-button")
    .text("Usuń ostatni przykład")
    .appendTo(sentenceButtons)

    parentButton.before(oneWordSentencesContainer);
  } else {
    parentButton.before(sentenceContaner);
  }
}
