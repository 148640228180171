

  const $addSynonymButton = $('.add-synonym-button');
  const $formSynonyms = $('.form-inline.synonyms')
  const $removeSynonymButton = $('.remove-synonym-button');
  const $sendSynonymButton = $('.send-synonym-button');

  $addSynonymButton.on('click', function () {
    addSynonym();
  })

  $sendSynonymButton.on('click', function () {
    const data = collectingData()
    console.log("sprawdzamy wiadomośc wychodzącą")
    console.log(data)
    dataSending(data);
  })

  function collectingData () {
    let data = [];
    $('.all-the-synonyms').find('.one-synonym-row').each(function(i, el) {
      let synonym;
      let base;
      $(this).find(".base-for-synonym-input").each(function (k, el1) {
        if($(this).val()) {
          base = $(this).val();
        }
      })
      $(this).find(".synonym-input").each(function (k, el1) {
        if($(this).val()) {
          synonym = $(this).val();
        }
      })
      if(base && synonym) {
        data[i] = {}
        data[i].synonym = synonym
        data[i].base = base
      }
    })
    return data;
  }




  function addSynonym(data) {
      const $allSynonymRows = $('.all-the-synonyms');
      let div = document.createElement('div');
      $(div)
      .addClass("one-synonym-row")
      .appendTo($allSynonymRows)

      let baseForSynonym = document.createElement("div")
      $(baseForSynonym)
      .addClass("form-group base-for-synonym")
      .appendTo(div)

      let labelForBaseSynonym = document.createElement("label")
      $(labelForBaseSynonym)
      .text("Podstawa dla synonimu")
      .addClass("base-for-synonym-label")
      .appendTo(baseForSynonym)

      let inputForBaseSynonym = document.createElement("input")
      $(inputForBaseSynonym)
      .addClass("form-control base-for-synonym-input")
      .prop("type", "text")
      .appendTo(baseForSynonym)

      let synonym = document.createElement("div")
      $(synonym)
      .addClass("form-group synonym")
      .appendTo(div)

      let labelForBase = document.createElement("label")
      $(labelForBase)
      .addClass("synonym-label")
      .text("Synonim")
      .appendTo(synonym)

      let inputForSynonim = document.createElement("input")
      $(inputForSynonim)
      .addClass("form-control synonym-input")
      .prop("type", "text")
      .appendTo(synonym)

  }

  $formSynonyms.on('click' ,'.remove-synonym-button',function () {
    let $elementToRemove = $(this).parent().siblings('.all-the-synonyms').children().last();
    $elementToRemove.remove();
  })

  $formSynonyms.submit(function(e) {
    e.preventDefault();
  })

  function dataSending(data) {
    console.log("data")
    console.log(data)
    $sendSynonymButton.prop("disabled", true);
    $sendSynonymButton.prop("text", "Wysyłanie")
    console.log("jesteśmy w data sending");
    $.ajax({
    url: "https://api.localvoice.pl/v1/synonyms",
    method: "post",
    data: JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      }
    })
    .done(function (returnData) {
      console.log("UDAŁO SIĘ");
      $sendSynonymButton.text("Zapisz synonimy");
      $sendSynonymButton.prop("disabled", false);
      console.log(returnData);
      const textForSuccess = "Synonimy zostały wysłane";
      ShowMessage.popupTextMessageForSaveButtons(textForSuccess, this);

    })
    .catch((res) => {
      console.log("nie udało się");
      $sendSynonymButton.text("Zapisz synonimy");
      $sendSynonymButton.prop("disabled", false);
      const textForFail = "Wystąpił błąd. Synonimy nie zostały zapisane";
      ShowMessage.popupTextMessageForSaveButtons(textForFail, this);
    })
  }
