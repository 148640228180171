'use strict';

export default class ValidateStructure {
  constructor(structures, numberOfCourses) {
    this.structures = structures;
    this.numberOfCourses = numberOfCourses;
    this.comments = [];
  }

  validateNameOfCourses() {
    let comment;
    for(let i = 0; i < this.numberOfCourses; i++) {
      if(!$(this.structures[i]).find(".course-name-input").val()) {
        comment = 'Musisz podać nazwę kursu';
        this.comments.push(comment)
      }
      const reg = /course$/i;
      console.log(this.structures)
      const dupa = $(this.structures[i]).find(".course-name-input").val()
      console.log("structure", dupa);
      if(!($(this.structures[i]).find(".course-name-input").val())) {
        console.log("jesteśmy w if", reg.test($(this.structures[i]).find(".course-name-input").val()));

        comment = "Nazwa kursu musi zawierać słowo 'course'";
        this.comments.push(comment)
      }
    }
  }

  validateImageInCourseField() {
    let comment;
    for(let i = 0; i < this.numberOfCourses; i++) {
      if(!$(this.structures[i]).find(".course-image").attr('src')) {
        comment = 'Musisz dodać zdjęcie do nazwy kursu';
        this.comments.push(comment)
      }
    }
  }

  validateAllFieldsInCategoryForm() {
    let comment;
    for(let i = 0; i < this.numberOfCourses; i++) {
      const categoriesRows = $(this.structures[i]).find(".category-rows");
      $(categoriesRows).find(".one-category").each((i, category) => {
        console.log("category", category)
        console.log("category, val", $(category).find(".english-category-input").val())
        if(!$(category).find(".english-category-input").val()) {
          comment = 'Musisz podać nazwę kategorii';
          this.comments.push(comment);
        }

        const reg = /category$/i;
        if(!reg.test($(category).find(".english-category-input").val())) {
          comment = "Nazwa kategorii musi zawierać słowo 'category'";
          this.comments.push(comment)
        }

        if(!$(category).find(".polish-category-input").val()) {
          comment = 'Musisz podać opis do wszystkich kategorii';
          this.comments.push(comment);
        }

        if(!$(category).find("img.category-image").attr('src')) {
          comment = 'Musisz dodać zdjęcie do wszystkich kategorii';
          this.comments.push(comment);
        }
      })
    }
  }

  validateNumberOfCategoriesPerCourse() {
    let number = 0;
    let comment;
    for(let i = 0; i < this.numberOfCourses; i++) {
      const categoriesRows = $(this.structures[i]).find(".category-rows");
      $(categoriesRows).find(".one-category").each((i, category) => {
        number = i;
      })
      number+=1;
      console.log("ile number", number)
      if(number < 2) {
        comment = 'Musisz posiadać przynajmniej 2 kategorie w 1 kursie';
        this.comments.push(comment);
      }
      number = 0;
    }
  }

  validateAllFieldsInLessonForm() {
    let comment;
    for(let i = 0; i < this.numberOfCourses; i++) {
      $(this.structures[i]).find(".one-category").each((i, category) => {
        if($(category).find(".english-category-input").val()) {
          $(category).find(".one-lesson").each((n, lesson) => {
            if(!$(lesson).find(".english-lesson-input").val()) {
              comment = "Musisz podać nazwę do wszystkich lekcji";
              this.comments.push(comment);
            }
            if(!$(lesson).find(".polish-lesson-input").val()) {
              comment = "Musisz podać opis do wszystkich lekcji";
              this.comments.push(comment);
            }
            if(!$(lesson).find("img.myImage").attr('src')) {
              comment = "Musisz dodać zdjęcie do wszystkich lekcji";
              this.comments.push(comment);
            }
          })
        }
      })
    }
  }

  validateNumberOfLessonsPerCategory() {
    let number = 0;
    let comment;
    for(let i = 0; i < this.numberOfCourses; i++) {
      $(this.structures[i]).find(".one-category").each((i, category) => {
        $(category).find(".one-lesson").each((n, lesson) => {
          console.log("która lekcja", n);
          number = n;
        })
        number+=1;
        if(number < 2) {
          comment = 'Musisz posiadać przynajmniej 2 lekcje w 1 kategorii';
          this.comments.push(comment);
        }
        number = 0;
      })
    }
  }
}
