import logger                                       from './config/logger';
import { createBlobs, removeBlobs }                 from './utils/helperFunctions';
import ValidateStructure                            from './utils/validateStructure';
import ValidateLesson                               from './utils/validateLesson';
import { checkNumberOFCourses }                     from './structure';

export class NoBusinessError extends Error {};
export class NoUserError     extends Error {};

export class Token {
  static getIdToken () {
    const parsedToken = this.getParsedToken();
    if(parsedToken && parsedToken.idToken) return parsedToken.idToken;
    return null;
  }

  static getParsedToken() {
    const stringifyToken = localStorage.getItem('token');
    if(!stringifyToken) return null;
    return JSON.parse(stringifyToken);
  }

  static getAccessToken() {
    const parsedToken = this.getParsedToken();
    if(parsedToken && parsedToken.accessToken) return parsedToken.accessToken;
    return null;
  }

  static getUser() {
    const parsedToken = this.getParsedToken();
    if(parsedToken && parsedToken.idTokenPayload && parsedToken.idTokenPayload.email) return parsedToken.idTokenPayload.email;
    return null;
  } 
}

export class TargetBasedOnContext {
  static getTarget(context) {
    const target = $(context).parents(".structures").length ? "structure-icons" : "lesson-icons";
    return target;
  }

  static getClassForSavedButton(context, env) {
    const random = Math.floor(Math.random() * 100).toString();
    let classTarget;
    let target;
    let parent;

    if(typeof context === "string") target = context;
    else target = TargetBasedOnContext.getTarget(context);

    if(env) {
      if(target === "structure-icons") {
        switch(env) {
          case "test":
            parent = `.verification-structure`;
            classTarget = `.verification-structure .${random}`;
            break;
        }
      } else {
        switch(env) {
          case "test":
            parent = `.verification-button`;
            classTarget = `.verification-button .${random}`;
            break;
        }
      }
    } else {
      if(target === "structure-icons") {
        parent = `.save-structure`;
        classTarget = `.save-structure .${random}`;
      }
      else {
        parent = `.saving-buttons`;
        classTarget = `.saving-buttons .${random}`;
      }
    }


    return { classTarget, random, parent };
  }

  static getGeneralTarget(context) {
    const target = $(context).parents(".structures").length ? "structure-icons" : "lesson-icons";
    return target;
  }
}

export class ShowMessage {

  static picturesGeneratingMessage(text) {
    $('.verification-data-button').css("background-color", "green")
    $('.verification-data-button').prop("disabled", true).text(text)
  }

  static extractTextFromMessage(message) {
    console.log("EXTRACT TEXT FROM MESSAGE")
    console.log("message", message);
    try {
      logger("info", message, "extractTextFromMessage message:");
      let text;
      if(Array.isArray(message)) {
        text = "Aby zacząć testować: " + [...new Set(message)];
      } else if(typeof message === "string") {
        text = message;
      } else if(message.answer) {
        text = message.answer;
      } else {
        logger("error", message, "extractTextFromMessage")
        text = "there was an error";
      }
      return text;
    } catch(error) {
      throw error;
    }
  }

  static popupTextMessageForSaveButtons(message, context, time=5000) {
    logger("info", "popupTextMessageForSaveButtons");

    const { classTarget, random, parent } = TargetBasedOnContext.getClassForSavedButton(context);
    const text = ShowMessage.extractTextFromMessage(message);
    $(classTarget).addClass('show').text(text);

    createBlobs(parent, random, text);
    setTimeout(function () {
      removeBlobs(classTarget)
    }, time);
  }

  static popupTextMessageForEnvButtons(env, message, context, time=7000) {
    try {
      logger("info", "popupTextMessageForEnvButtons");
      console.log("message", message);
      console.log("env", env);

      const { classTarget, random, parent } = TargetBasedOnContext.getClassForSavedButton(context, env)
      const text = ShowMessage.extractTextFromMessage(message)
      $(classTarget).addClass('show').text(text);
      console.log("classTarget", classTarget);
      console.log("random", random);
      console.log("parent", parent);

      createBlobs(parent, random, text);
      setTimeout(function () {
        removeBlobs(classTarget);
      }, time);

    } catch(error) {
      logger("error", error, "popupTextMessageForEnvButtons");
    }
  }

  static popupHTMLMessage(target, message, time=5000) {
    target.addClass('show').html(message);
    setTimeout(function () {
      target.removeClass('show')
    }, time)
  }
}

export const baseCheckingBeforeSavingPicture = (target) => {
  if(target === 'structure-icons') {
    const { number: numberOfCourses, structures } = checkNumberOFCourses();
    const structureValidator = new ValidateStructure(structures, numberOfCourses);
    structureValidator.validateNameOfCourses();
    if(structureValidator.comments.length === 0) {
      return true;
    } else {
      const message = 'Aby zapisać zdjęcie musisz uzupełnic podstawowe pola';
      ShowMessage.popupTextMessageForSaveButtons(message, target)
    }
  } else {
    const validateLesson = new ValidateLesson();
    const comments = validateLesson.validateMainFields();
    if(comments.length === 0) {
      return true;
    } else {
      const message = 'Aby zapisać zdjęcie musisz uzupełnic podstawowe pola';
      ShowMessage.popupTextMessageForSaveButtons(message, target)
    }
  }
}


export function checkingUser() {
   const token = JSON.parse(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')) : undefined;
   if(token && token.idTokenPayload) {
     const user = token.idTokenPayload.email
     return user
   } else {
     throw new NoUserError();
   }
 }

export function fetchingUserAndBusiness() {
  logger("info", "fetchingUserAndBusiness");
  const noUserResponse = "Token expired. Try to login one more time"

  const user = Token.getUser();
  const idToken = Token.getIdToken();
  if(!user || !idToken) return ShowMessage.popupTextMessageForSaveButtons(noUserResponse, "lesson");

  return new Promise((resolve, reject) => {
   $.ajax({
   url: process.env.API_GET_BUSINESS,
   method: "get",
   headers: {
     'x-user': user,
     'Accept': 'application/json',
     'Content-Type': 'application/json',
     'Authorization': idToken
     }
   })
   .done((response) => {
     if(response.statusCode === 200) {
      const parsedResponse = JSON.parse(response.body)
      const result = {};
      result.business = parsedResponse.data.business;
      result.user = user;
      return resolve(result)
     }
     reject(response);
   })
   .catch((error) => {
     reject(error)
    })
  })
}
