import logger                                           from "../config/logger";

export default class ValidateLesson {
  lesson = {
    course:'',
    name:'',
    category: '',
    translatedCategory:'',
    todaysLesson: {
      words: {},
      chat: {},
      translate: {},
      challengeForToday: {}
    }
  }


  constructor() {
    logger("info", 'ValidateLesson constructor');

    this.result = 0;
    this.comments = [];
  }


  getNumberOfExceptions() {
    return this.result;
  }

  getComment () {
    const modifyComments = this.comments.join(',').replace(/,/g, '. ')
    const message = `Nie możesz jeszcze testować. Ilość pól do uzupełnienia: ${this.result}. ${modifyComments}`
    return message;
  }

  validateMainFields() {
    logger("info", "validateMainFields");

    let comment;
    // sprawdzamy czy jest nazwa kursu po ang
    if($('.course-select option:selected').val()) {
      this.lesson.course = $('.course-select option:selected').val().toLowerCase();
      $('.form-group.course').css("background-color", "#f7f7f7");
      $('.form-group.course .course-label').text("Nazwa kategorii po angielsku");
    } else {
      comment = "Aby zapisać dane musisz wprowadzić nazwę kursu po angielsku";
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.course').css("background-color", "crimson");
      $('.form-group.course .course-label').text(comment);
    }

    // sprawdzamy czy jest nazwa kategorii po ang
    if($('.english-category-select option:selected').val()) {
      this.lesson.category = $('.english-category-select option:selected').val().toLowerCase();
      $('.form-group.category-name').css("background-color", "#f7f7f7")
      $('.form-group.category-name .english-category-label').text("Nazwa kategorii po angielsku")
    } else {
      comment = "Aby zapisać dane musisz wprowadzić nazwę kategorii po angielsku";
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.category-name').css("background-color", "crimson")
      $('.form-group.category-name .english-category-label').text(comment);
    }
    // sprawdzamy czy jest nazwa kategorii po pl
    if($('.polish-category-select option:selected').val()) {
      this.lesson.translatedCategory = $('.polish-category-select option:selected').val();
      $('.form-group.category-description').css("background-color", "#f7f7f7")
      $('.form-group.category-description .polish-category-label').text("Nazwa kategorii po polsku")
    } else {
      comment = "Aby zapisać dane musisz wprowadzić nazwę kategorii po polsku";
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.category-description').css("background-color", "crimson")
      $('.form-group.category-description english-category-label').text(comment);
    }
    // sprawdzamy czy jest nazwa lekcji po ang
    if($('.english-lesson-select option:selected').val()) {
      this.lesson.lesson = $('.english-lesson-select option:selected').val().toLowerCase()
      $('.form-group.lesson-name').css("background-color", "#f7f7f7")
      $('.form-group.lesson-name .english-lesson-label').text("Nazwa lekcji po angielsksu")
      let reg = /[.?!]/g;
      if(reg.test(this.lesson.lesson)) {
        comment = "Nazwę lekcji może zawierać jedynie litery i apostrofy - bez znaków specialnych jak kropki czy przecinki";
        this.result+=1;
        this.comments.push(comment);
        $('.form-group.lesson-name').css("background-color", "crimson");
        $('.form-group.lesson-name .english-lesson-label').text(comment);
      } else {
        $('.form-group.lesson-name').css("background-color", "#f7f7f7");
        $('.form-group.lesson-name .english-lesson-label').text("Nazwa lekcji po angielsksu");
      }
    } else {
      comment = "Aby zapisać dane musisz wprowadzić nazwę lekcji po angielsku"
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.lesson-name').css("background-color", "crimson");
      $('.form-group.lesson-name .english-lesson-label').text(comment)
    }
    // sprawdzamy czy jest nazwa lekcji po polsku
    if($('.polish-lesson-select option:selected').val()) {
      this.lesson.translatedLesson = $('.polish-lesson-select option:selected').val();
      $('.form-group.lesson-description').css("background-color", "white")
      $('.polish-lesson-label').text("Nazwa lekcji po polsku")
    } else {
      comment = "Aby zapisać dane musisz wprowadzić nazwę lekcji po polsku";
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.lesson-description').css("background-color", "crimson")
      $('.polish-lesson-label').text(comment);
    }

    // sprawdzamy czy jest opis lekcji po polski
    if($('.lesson-polish-description-input').val()) {
      this.lesson.todaysLesson.words.lesson_description_translate = $('.lesson-polish-description-input').val();
      $('.form-group.lesson-long-description-in-orginal-language').css("background-color", "white")
      $('.lesson-polish-description-label').text("Opis lekcji po polsku")
      let lastLetter = this.lesson.todaysLesson.words.lesson_description_translate.length -1;
      if(this.lesson.todaysLesson.words.lesson_description_translate.charAt(lastLetter) != '.' || this.lesson.todaysLesson.words.lesson_description_translate.charAt(lastLetter) != '?' || this.lesson.todaysLesson.words.lesson_description_translate.charAt(lastLetter) != '!') {
        this.lesson.todaysLesson.words.lesson_description_translate = `${this.lesson.todaysLesson.words.lesson_description_translate}.`;
      }
    } else {
      comment = "Aby zapisać dane musisz wprowadzić opis lekcji po polsku";
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.lesson-long-description-in-orginal-language').css("background-color", "crimson")
      $('.lesson-polish-description-label').text(comment);
    }

    // sprawdzamy czy jest opis lekcji po angielsku
    if($('.lesson-english-description-input').val()) {
      this.lesson.todaysLesson.words.lesson_description = $('.lesson-english-description-input').val();
      $('.form-group.lesson-long-description-in-different-language').css("background-color", "white")
      $('.lesson-english-description-label').text("Opis lekcji po angielsku")
      let lastLetter = this.lesson.todaysLesson.words.lesson_description.length -1;
      if(this.lesson.todaysLesson.words.lesson_description.charAt(lastLetter) != '.' || this.lesson.todaysLesson.words.lesson_description.charAt(lastLetter) != '?' || this.lesson.todaysLesson.words.lesson_description.charAt(lastLetter) != '!') {
        this.lesson.todaysLesson.words.lesson_description = `${this.lesson.todaysLesson.words.lesson_description}.`
      }
    } else {
      comment = "Aby zapisać dane musisz wprowadzić opis lekcji po angielsku";
      this.result+=1;
      this.comments.push(comment);
      $('.form-group.lesson-long-description-in-different-language').css("background-color", "crimson")
      $('.lesson-english-description-label').text(comment);
    }
    return this.comments;
  }

  validateWordsAndExamples() {
    logger("info", "validateWordsAndExamples");

    const examples_for_word = [];
    const words_to_repeat = [];
    let word = '';

    $('.words-container').find('.word-container').each((i, el) => {
      examples_for_word[i] = []
      if($(el).find(".english-word-input").val()) {
        word = $(el).find(".english-word-input").val()
        words_to_repeat.push(word)
      }
      $(el).find('.examples-container').each((j, el1) => {
        examples_for_word[i]
        $(el1).find('.words-examples').each((k, el2) => {
          examples_for_word[i][k] = [];

          // pobieramy część mowy
          let part_of_the_speech = $(el2).find("option:selected").val();
          if(part_of_the_speech) {
            $(el2).find('.part-of-the-speech-select').css("background-color","white");
            examples_for_word[i][k].push(part_of_the_speech)
          } else {
            this.result+=1;
            $(el2).find('.part-of-the-speech-select').css("background-color","crimson");
          }

          // pobieramy polskie znaczenie słowa
          let explanation_input = $(el2).find('.polish-word-explanation-input').val();
          if(explanation_input) {
            $(el2).find('.polish-word-explanation-input').css("background-color", "white");
            examples_for_word[i][k].push(explanation_input)
          } else if(!explanation_input && part_of_the_speech != "Popularna fraza") {
            this.result+=1;
            $(el2).find('.polish-word-explanation-input').css("background-color", "crimson");
          } else {
            examples_for_word[i][k].push(null)
          }

          // pobieramy przykład po angielsku
          let english_example_input = $(el2).find('.english-word-example-input').val();
          if(english_example_input) {
            $(el2).find('.english-word-example-input').css("background-color","#39CCCC");
            examples_for_word[i][k].push(english_example_input)
          } else {
            this.result+=1;
            $(el2).find('.english-word-example-input').css("background-color","crimson");
          }

          // pobieramy przykład po polsku
          let polish_example_input = $(el2).find('.polish-word-example-input').val();
          if(polish_example_input) {
            $(el2).find('.polish-word-example-input').css("background-color","white");
            examples_for_word[i][k].push(polish_example_input)
          } else {
            this.result+=1;
            $(el2).find('.polish-word-example-input').css("background-color","crimson");
          }
          // pobieramy ciekawostki gramatyczne
          let polish_facts = $(el2).find('.polish-facts-input').val();
          if(polish_facts) {
            $(el2).find('.polish-word-example-input').css("background-color","white");
            examples_for_word[i][k].push(polish_facts)
          } else {
            examples_for_word[i][k].push(null)
          }

          // pobieramy źródła dla małych obrazków
          if($(el2).find('.inputProcessContainer')) {
            const src = $(el2).find('img.myImage').attr('src')
            examples_for_word[i][k].push(src);
          }

          // pobieramy switch dla zdjęcia z zarówką
          if($(el2).find('.switch')) {
            const checkbox = el2.querySelector('.switch input');
            examples_for_word[i][k].push(checkbox.checked);
          }
        })
      })
    })
    const result = {};
    result.examples_for_word = examples_for_word;
    result.words_to_repeat = words_to_repeat;
    return result;
  }

  validateListeningSentences() {
    logger("info", "validateListeningSentences");

    const link_to_mp3 = [];
    const right_answer = [];
    const polish_word_sentences = [];
    let additionalChallenge = [];

    $('.all-words-sentence-container').find('.one-word-sentences-container').each((i, el) => {
      link_to_mp3[i] = [];
      right_answer[i] = [];
      polish_word_sentences[i] = [];
        $(el).find(".english-sentence-example").each((k, el1) => {
          link_to_mp3[i][k] = [];
          $(el1).find("input.listening-comprehension-link-input").each((n, el2) => {
            if($(el2).val()) {
              let inputValue = $(el2).val()
              if(inputValue != "") {
                link_to_mp3[i][k].push(inputValue)
                additionalChallenge.push(inputValue)
                $(el2).css("background-color","white");
              } else {
                this.result+=1;
                $(el2).css("background-color","crimson");
              }
            }
          })
          right_answer[i][k] = [];
          $(el1).find("input.listening-comprehension-answer-input").each((n, el3) => {
            if($(el3).val()) {
              let inputValue = $(el3).val()
              if(inputValue != "") {
                right_answer[i][k].push(inputValue)
                additionalChallenge.push(inputValue)
                $(el3).css("background-color","white");
              } else {
                this.result+=1;
                $(el3).css("background-color","crimson");
              }
            }
          })
        })

        $(el).find(".polish-sentence-example").each((m, el3) => {
          polish_word_sentences[i][m] = []
          $(el3).find("input.polish-phrase-input").each((n, el4) => {
            if($(el4).val()) {
              let inputValue = $(el4).val();
              if(inputValue) {
                polish_word_sentences[i][m].push(inputValue)
                $(el4).css("background-color","white");
              } else {
                this.result+=1;
                $(el4).css("background-color","crimson");
              }
            }
          })
        })
      })

      const result = {}
      result.link_to_mp3 = link_to_mp3;
      result.right_answer = right_answer;
      result.polish_word_sentences = polish_word_sentences;
      return result;
    }

  validateChat() {
    logger("info", "validateChat");

    const chat = [];
    const chat_translate = [];
    let polish_chat_description;
    let english_chat_description;

    // część odpowiedzialna za czat
    // pobieramy opis czatu po polsku

    if($('.polish-chat-description-input').val()) {
      polish_chat_description = $('.polish-chat-description-input').val()
      $(".polish-chat-description-input").css("background-color", "white")
    } else {
      this.result+=1;
      this.comments.push(`Opis dla czatu po polsku`);
      $(".polish-chat-description-input").css("background-color", "crimson");
    }

    // pobieramy opsi czatu po angielsku
    if($('.english-chat-description-input').val()) {
      english_chat_description = $('.english-chat-description-input').val()
      $(".english-chat-description-input").css("background-color", "white")
    } else {
      this.result+=1;
      this.comments.push(`Opis dla czatu po angielsku`);
      $(".english-chat-description-input").css("background-color", "crimson")
    }

    // pobieramy dialogi czatu
    $('.all-the-rools').find('.one-chat-row').each((i, el) => {
      $(el).children(".english-chat-row").each( (j, el1) => {
        $(el1).find(".english-user-group").each((k, el2) => {
          if($(el2).find('input').val()) {
            chat.push($(el2).find('input').val())
            $(el2).find('input').css("background-color","#7FDBFF");
          } else {
            this.result+=1;
            $(el2).find('input').css("background-color","crimson");
          }
        })

        $(el1).find(".english-google-group").each((l, el3) => {
          if($(el3).find('input').val()) {
            chat.push($(el3).find('input').val())
            $(el3).find('input').css("background-color","#7FDBFF");
          } else {
            this.result+=1;
            $(el3).find('input').css("background-color","crimson");
          }
        })
      })

      $(el).children(".polish-chat-row").each((m, el4) => {
        $(el4).find(".polish-user-group").each((n, el5) => {
          if($(el5).find('input').val()) {
            chat_translate.push($(el5).find('input').val())
            $(el5).find('input').css("background-color","white");
          } else {
            this.result+=1;
            $(el5).find('input').css("background-color","crimson");

          }
        })

        $(el4).find(".polish-google-group").each((o, el6) => {
          if($(el6).find('input').val()) {
            chat_translate.push($(el6).find('input').val())
            $(el6).find('input').css("background-color","white");
          } else {
            this.result+=1;
            $(el6).find('input').css("background-color","crimson");
          }
        })
      })
    })
    const result = {};
    result.chat = chat;
    result.chat_translate = chat_translate;
    result.english_chat_description = english_chat_description;
    result.polish_chat_description = polish_chat_description;

    return result;
  }


  validateChallenge() {
    logger("info", "validateChallenge");

    const challenge_for_today = [];
    // część odpowiedzialna za wyzwania
      $('.challenge-rows').find('.one-challenge').each((i, el) => {
        $(el).children(".polish-challenge-row").each((k, el1) => {
          if($(el1).find('input').val()) {
            challenge_for_today.push($(el1).find('input').val())
            $(el1).find('input').css("background-color","#f9bbbb");
          } else {
            this.result+=1;
            $(el1).find('input').css("background-color","crimson");
          }
        })

        $(el).find(".english-challenge-row").each((j, el2) => {
          if($(el2).find('input').val()) {
            challenge_for_today.push($(el2).find('input').val())
            $(el2).find('input').css("background-color","#f9bbbb");
          } else {
            this.result+=1;
            $(el2).find('input').css("background-color","crimson");
          }
        })
      })

      const result = {};
      result.challenge_for_today = challenge_for_today;
      return result;
  }

  mergeLessonToTransferingObject(wordsPart, listeningPart, chatPart, challengePart) {
    logger("info", "mergeLessonToTransferingObject")

    for(let i = 0; i < wordsPart.words_to_repeat.length; i++) {
      this.lesson.todaysLesson.words[wordsPart.words_to_repeat[i]] = {};
      this.lesson.todaysLesson.words[wordsPart.words_to_repeat[i]].examplesForWord = [];
      for(let j = 0; j < wordsPart.examples_for_word.length; j++) {
        this.lesson.todaysLesson.words[wordsPart.words_to_repeat[i]].examplesForWord = wordsPart.examples_for_word[i];
      }
      this.lesson.todaysLesson.words[wordsPart.words_to_repeat[i]].sentences = [];

      if(listeningPart.polish_word_sentences[i]) {
        console.log(listeningPart.polish_word_sentences)
        for(let k = 0; k < listeningPart.polish_word_sentences[i].length; k++) {
          let oneSentence = {}
          oneSentence.translated_array = listeningPart.polish_word_sentences[i][k]
          oneSentence.sentence_to_repead = listeningPart.right_answer[i][k];
          oneSentence.link_to_mp3 = listeningPart.link_to_mp3[i][k];
          this.lesson.todaysLesson.words[wordsPart.words_to_repeat[i]].sentences.push(oneSentence)
        }
      }
    }
    this.lesson.todaysLesson.challengeForToday = challengePart.challenge_for_today
    this.lesson.todaysLesson.chat = chatPart.chat;
    this.lesson.todaysLesson.translate = chatPart.chat_translate;
    this.lesson.todaysLesson.words.words_to_repeat = wordsPart.words_to_repeat;

    if(chatPart.english_chat_description) {
      this.lesson.todaysLesson.chatDescription = chatPart.english_chat_description;
    }

    if(chatPart.polish_chat_description) {
    this.lesson.todaysLesson.translatedChatDescription = chatPart.polish_chat_description;
    }

    return this.lesson;
  }

}
