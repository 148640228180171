$(document).ready(function() {

  // sprawdzamy czy jest opis lekcji po polski
  if($('.lesson-polish-description-input').val()) {
    $('.lesson-polish-description-input').val("");
    $('.lesson-polish-description-input').css("background-color", "none")
    $('.lesson-polish-description-label').text("Wstęp do dzisiejszej lekcji po polsku")
  }

  // sprawdzamy czy jest opis lekcji po angielsku
  if($('.lesson-english-description-input').val()) {
    $('.lesson-english-description-input').val("");
    $('.lesson-english-description-input').css("background-color", "none")
    $('.lesson-english-description-label').text("Wstęp do dzisiejszej lekcji po angielsku")
  }

  if($('.all-lesson-words-input').val()) {
    $('.all-lesson-words-input').val("")
    $('.all-lesson-words-input').css("background-color", "none")
    $('.all-lesson-words-label').text("Wszystkie dzisiejsze słówka po przecinku - NIE UZUPEŁNIASZ")
  }


  if($('.english-chat-description-input').val()) {
    $('.english-chat-description-input').val('')
  }

  if($('.polish-chat-description-input').val()) {
    $('.polish-chat-description-input').val('')
  }

  if($(".polish-challenge-input").val()) {
    $(".polish-challenge-input").val("")
  }

  if($(".english-challenge-input").val()) {
    $(".english-challenge-input").val("")
  }

  if($(".listening-module-text-area").val()) {
    $(".listening-module-text-area").val("")
  }

})
